// import React from 'react';

import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';

import {
  Table,
  Label,
  Row,
  Col,
  UncontrolledCollapse,
  Card,
  CardBody,
  Input,
  Badge,
} from 'reactstrap';

import { NavLink } from 'react-router-dom';

import { Check, Dash } from '../common/assets';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas)

export default class PricingTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isChecked: false };
    this.state = { checked: false };
    this.state = { selectedPriceLevel: "Business" };
    this.state = {pricelist: []}; // for employees dropdown list
    
    this.selectPrice = this.selectPrice.bind(this); // for employees dropdown

    this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
    
    this.handleChange = this.handleChange.bind(this); // Hide Show Addon div
    
    this.handleClick = this.handleClick.bind(this); // Show Selected Plan
  }
  
  // employees dropdown list
  componentDidMount() {
    this.setState({
      pricelist: [
        {name: '25 Employees', value: '$0.00'},
        {name: '50 Employees', value: '$50.00'},
        {name: '200 or Above', value: '$200.00'},
      ],
      value: '$0.00',
    });
  }

  // For Employees dropdown
  selectPrice = (e) => {
    this.setState({ value: event.target.value });
    // console.log('Selected Plan: ', price);
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  handleChange() {
    this.setState({
      checked: !this.state.checked
    })
  }

  handleClick(plan) {
    this.setState({
      selectedPriceLevel: plan
    });
  }

  render() {
  // employees dropdown code
    const { pricelist } = this.state;
    
  // employees dropdown code ends.

    let startup, business, time, yearly;
    if (this.state.isChecked) {
      startup = '99.99'
      business = '249.99'
      time = 'year'
      yearly = true
    } else {
      startup = '9.99'
      business = '24.99'
      time = 'month'
      yearly = false
    }

    const FREEPRICELEVEL = "Free";
    const STARTUPPRICELEVEL = "Startup";
    const BUSINESSPRICELEVEL = "Business";

    const hidden = this.state.checked ? '' : 'd-none'; // Hide Show Addon div

    const { options, value } = this.state;

    return (
      <div>
        <Table borderless className="mb-0 no-border">
          <tbody>
            <tr>
              <th scope="row"></th>
              <td></td>
              <td>
                <span className="recommended">
                  Recommended
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </Table>

        <Table borderless className="white-bg">
          <thead>
            <tr>
              <th>
                <h4>Pay less with yearly plan</h4>

                <p className="d-sm-none">Mo</p>
                <p className="large-txt">Monthly</p>
                <Label className="switch">
                  <input type="checkbox" onChange={this.handleChecked} />
                  <span className="slider round"></span>
                </Label>
                <p className="large-txt">Yearly</p>
                <p className="d-sm-none">Yr</p>

              </th>
              <th>
                <h4>Free</h4>
                <h2><sup>$</sup>0</h2>
                <h6>Forever</h6>
              </th>
              <th>
                <h4>Startup</h4>
                <h2><sup>$</sup>{startup}</h2>
                <h6>per {time}</h6>
              </th>
              <th>
                <h4>Business</h4>
                <h2><sup>$</sup>{business}</h2>
                <h6>per {time}</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Users</th>
              <td>Single User</td>
              <td>Upto 5 Users</td>
              <td>Upto 10 Users</td>

            </tr>
            <tr>
              <th scope="row">Unlimited Storage</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Unlimited Invoices</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Unlimited Estimates</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Number of clients</th>
              <td>5</td>
              <td>50</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th scope="row">Dedicated Phone Support</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Multi-payment Processing</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">ACH Payments</th>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Custom Invoice</th>
              <td><img src={Dash} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Your Branded Invoice</th>
              <td><img src={Dash} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
            <tr>
              <th scope="row">Monthly Status Reports</th>
              <td><img src={Dash} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
              <td><img src={Check} alt="check" width="20" /></td>
            </tr>
          </tbody>
        </Table>

        <Table borderless className="mb-0 no-border">
          <tbody>
            <tr>
              <th scope="row"></th>
              <td>
                {/* <a href={"/sign-up?plan=free&yearly=" + yearly} >Select</a> */}
                <a
                  href="#"
                  id="selectPlans"
                  onClick={() => this.handleClick("Free")}
                >Select</a>
              </td>

              <td>
                {/* <a href={"/sign-up?plan=startup&yearly=" + yearly} className="green-main">Select</a> */}
                <a 
                  href="#"
                  id="selectPlans"
                  className="green-main"
                  onClick={() => this.handleClick("Startup")}
                >Select</a>  
              </td>

              <td>
                {/* <a href={"/sign-up?plan=business&yearly=" + yearly}>Select</a> */}
                <a 
                  href="#"
                  id="selectPlans" 
                  onClick={() => this.handleClick("Business")}
                >Select</a>  
              </td>
            </tr>
          </tbody>
        </Table>

        <UncontrolledCollapse toggler="#selectPlans" className="mt-5">
          <Table borderless className="mb-0 no-border text-left">
            <tbody>
              <tr>
                <td>
                  <Card>
                    <CardBody className="px-3 py-0">
                      
                      {this.state.selectedPriceLevel == FREEPRICELEVEL &&
                        // { Free Plan }
                        <div >
                        <Row className="border-bottom">
                          <Col xs="9" className="border-right">
                            <h4 className="my-3">Awesome, you’ve selected to go with Free Plan</h4>
                          </Col>
                          <Col xs="3" className="text-right">
                            <h4 className="my-3">$0.00</h4>
                          </Col>
                        </Row>
                       </div>
                      }

                      {this.state.selectedPriceLevel == STARTUPPRICELEVEL &&
                          // {/* Startup Plan */}
                          <div >
                          <Row className="border-bottom">
                            <Col xs="9" className="border-right">
                              <h4 className="my-3">Awesome, you’ve selected to go with Startup Plan</h4>
                            </Col>
                            <Col xs="3" className="text-right">
                              <h4 className="my-3">${startup}</h4>
                            </Col>
                          </Row>
                        </div>
                      }

                      {this.state.selectedPriceLevel == BUSINESSPRICELEVEL &&
                      // {/* Business Plan */}
                        <div >
                          <Row className="border-bottom">
                            <Col xs="9" className="border-right">
                              <h4 className="my-3">Awesome, you’ve selected to go with Business Plan</h4>
                            </Col>
                            <Col xs="3" className="text-right">
                              <h4 className="my-3">${business}</h4>
                            </Col>
                          </Row>
                        </div>
                      }

                      <Row className="border-bottom">
                        <Col md="12">
                          <h5 className="my-3">We have something more for you, choose addon that helps your business.</h5>
                        </Col>
                      </Row>

                      <Row className="border-bottom">
                        <Col md="12">
                          <h5 className="my-3">
                            Timesheet for employees 
                            <FontAwesomeIcon className="ml-2" icon={['fas', 'question-circle']} />
                            <Badge color="success" pill className="mx-3">New</Badge>
                            <Badge color="primary" pill>Free for Limited Time</Badge>
                          
                            <Label className="switch float-right my-1">
                              <input 
                                type="checkbox" 
                                onChange={ this.handleChange }
                              />
                              <span className="slider round"></span>
                            </Label>
                          </h5>

                        </Col>
                      </Row>
                      <div className={ hidden }>
                        <Row className="border-bottom">
                          <Col xs="9" className="border-right">
                            <h4 className="my-3">
                              Select number of employees
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect" 
                                className="w-auto d-inline-block ml-3"
                                onChange={this.selectPrice}
                                value={value}
                              >
                                
                                {pricelist.map((item, i) => {
                                  return (
                                    <option key={i} data-price={item.value} value={item.value}>{item.name}</option>
                                  )
                                })}

                              </Input>

                            </h4>
                            
                          </Col>
                          <Col xs="3" className="text-right">
                            
                              <h4 className="my-3">{value}</h4>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="9" className="border-right">
                            <h4 className="my-3">Total Amount</h4>
                          </Col>
                          <Col xs="3" className="text-right">
                            <h4 className="my-3">$49.98</h4>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                  
                  <Row>
                <Col className="text-center">
                  <NavLink to="/sign-up" className="mt-4 py-3 px-5 w-auto green-main">Let's Start <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ml-3" /></NavLink>
                </Col>
              </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </UncontrolledCollapse>
   
      </div>
    );
  }
}