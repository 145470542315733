import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

function Privacy(){
  return (
    <Container className="py-5">
      <Row>
        <Col className="text-center terms">
          <p><i>Effective May 12, 2019</i></p>
        </Col>
      </Row>

      <Row>
        <Col className="text-left terms mt-3">

          <p>We, Invoease LLC (“Invoease”), have established this privacy policy to inform you how we treat the information you provide through our website and applications (collectively, “Invoease’s Service”).  We also want to tell you about the other information that we collect in the application, and how we treat this information. We follow this privacy policy in accordance with local law in the places where we operate.</p>

          <p>Our site and application may provide you with hyperlinks to other sites not owned or controlled by us and we are not responsible for the privacy practices of any site that is not owned or controlled by us. We encourage you to be aware when you leave our site or applications and to read the privacy policies of these other sites.</p>

          <ol>
            <li>
              <strong>What Types of Information We Receive</strong>

              <ul>
                <li>
                  <strong>Personal Information</strong>
                  <p>When you use INVOEASE’s Service, you provide documents that contain personal information from or about you such as your name, business name, email address, mailing address, and telephone number(s).  All of the foregoing information is referenced herein as your “Personal Information.”</p>
                </li>

                <li>
                  <strong>Your Payment Information</strong>
                  <p>To pay for your subscription to INVOEASE’s Service, you submit your credit card number or billing information (“Payment Information”).  Although your Payment Information is used each subscription period until you cancel, INVOEASE does not store or process your Payment Information.  Your Payment Information is submitted directly to and stored by our various payments processors we offer in our platform.  INVOEASE chose Stripe as its payments processor in part because of its ability to secure your Payment Information in compliance with industry standards.  You should familiarize yourself with Stripe’s privacy and security policies if you have concerns about your Payment Information.  INVOEASE will notify you if it is informed that the security of your Payment Information has been compromised, however INVOEASE assumes no obligation to notify you if it is only generally aware of a security incident involving Stripe.</p>
                </li>

                <li>
                  <strong>Your Customer’s Payment Information</strong>
                  <p>In the course of utilizing INVOEASE’s Service, you submit your customer’s bank account, routing numbers and payment amounts (“Your Customer’s Payment Information”).</p>
                </li>

                <li>
                  <strong>Technical Information</strong>
                  <p>When you use INVOEASE’s Service, you also automatically provide technical information to us such as your device identifier or IP address.</p>
                </li>
              </ul>
            </li>

            <li>
              <strong>How We Collect Information</strong>
              <ul>
                <li>We collect all of the information you provide to us through the activities described above.</li>

                <li>We collect information through technology, such as cookies, Flash cookies and Web beacons, including when you visit our site and application, use our applications, or open emails from us.</li>

                <li>We may acquire information from other sources to update or supplement the information you provided or we collected automatically.</li>
              </ul>
            </li>

            <li>
              <strong>How We Use Information</strong>
              <p>We may use your personal information to:</p>

              <ul>
                <li>Provide you with the products and services you request;</li>
                <li>Communicate with you about your account or transactions;</li>
                <li>Improve our products, services and operations;</li>
                <li>Personalize your experience on our site and application;</li>
                <li>Detect, investigate and prevent activities that may violate our policies or be illegal;</li>
                <li>Review whether it is responsive to a subpoena or other legal request we have received; and</li>
                <li>Comply with other purposes disclosed to you at the time you provide your Personal Information, or as otherwise set forth in this Privacy Policy.</li>
              </ul>

              <p>We use Your Customer’s Payment Information only in providing INVOEASE’s Service to You.</p>

              <p>We may use all other information you provide to us, that is information excluding your Personal Information and Your Customer’s Payment Information, for any purpose.</p>
            </li>

            <li>
              <strong>When We Share Information</strong>
              <p>The limited instances of when we may share your Personal Information with others are:</p>

              <ul>
                <li>When, as may be necessary or appropriate in providing INVOEASE’s Service, we utilize third-party vendors or partners to assist with your instant verifications or bank authentication;</li>
                <li>When you have otherwise given us your express permission to do so;</li>
                <li>When we share your information with third parties in connection with the sale of our business, to enforce our Terms and Conditions or rules, to ensure the safety and security of our users and third parties, or to protect our rights and property and the rights and property of our users and third parties; or</li>
                <li>When we determine your information is responsive to a subpoena or other legal request.</li>
              </ul>

              <p>Please note that once we share your information with another company, the other company’s privacy practices will govern their use of the information.</p>

              <p>We do not, in the ordinary course of business, sell your Personal Information to third-parties for marketing purposes.</p>

              <p>We may, as may be necessary or appropriate in providing INVOEASE’s Service, share Your Customer’s Payment Information in the course of utilizing third-party vendors or partners to assist with your instant verifications or bank authentication.</p>
            </li>

            <li>
              <strong>How We Secure Data</strong>
              <p>The security of your information is important to us, because we know that the security of your information is important to you. We have implemented security procedures that are intended to protect your Personal Information and Your Customer’s Payment Information, including data encryption, secure SSL communications, and the use of a data center with its own built-in firewalls and security monitoring 24 hours a day, seven days a week. From time to time, we review and update our procedures to consider appropriate new security technology and methods.</p>
              <p>Please be aware of the following, however:</p>

              <ul>
                <li>No security measures are perfect or impenetrable. If, despite our security measures, your information has been compromised, we will notify you in accordance with our legal obligations in your jurisdiction.</li>

                <li>Depending on how you connect to the internet or your network, the information you send to us electronically may not be secure when you transmit it to us. We recommend that you avoid channels that are not secure when you transmit or communicate sensitive or confidential information to us.</li>
              </ul>

              <p>We may transfer your information to locations around the world. Wherever we control the transfer, storage, or processing of your personal information, we will take reasonable steps to safeguard the privacy of your information.</p>
            </li>

            <li>
              <strong>How You Control Your Information</strong>

              <p>You may modify, change, or correct your information in the same manner in which you originally provided it to us.</p>

              <p>If you want to delete your account, please contact us at <a href="mailto:info@invoease.com">info@invoease.com.</a> Once we receive your request, we will then take steps to delete your information within a commercially reasonable time from the end of your subscription. We may retain some information for our own backup, archival, record or audit purposes and as otherwise required by law, however, we will only retain the information for those purposes. Please note that we will only be able to delete your information that is within our control, and we will not be able delete your information that we had previously shared with others in accordance with this privacy policy.</p>
            </li>

            <li>
              <strong>How We Protect Children’s Privacy</strong>

              <p>We do not knowingly allow children under the age of 13 to use or register for INVOEASE’s Service, and therefore we do not knowingly collect personal information from children under the age of 13.</p>

              <p>If you believe that we have inadvertently collected personal information from a child under 13, please contact us at <a href="mailto:info@invoease.com">info@invoease.com</a> so we can take appropriate steps to delete the child’s personal information.</p>

              <p>We define “personal information from a child under 13” to include (1) a first and last name; (2) a home or other  physical address including street name and name of a city or town; (3) online contact information such as an email address or instant messaging user identifier; (4) a screen or user name; (5) a telephone number; (6) a persistent identifier that can be used to recognize a user over time and across different online services, including an IP address or a unique device identifier; (7) a photograph including the child’s image; or (8) geolocation information sufficient to identify street name and name of a city or town.</p>
            </li>

            <li>
              <strong>How Our Privacy Policy May Be Changed</strong>

              <p>From time to time, in response to new technologies, industry practices, regulatory requirements, changes in our services, or other circumstances, we may change this privacy policy. If these changes are material, we will provide notice to you, and, if we are required by applicable law, we will obtain your consent.  If you choose not to accept the new privacy policy, your account may be terminated.</p>
            </li>

            <li>
              <strong>How You May Comment and Inquire About This Policy</strong>

              <p>If you have a comment or question about this privacy policy, please contact us at <a href="mailto:info@invoease.com">info@invoease.com.</a></p>
            </li>

            <li>
              <strong>Notice to California Residents</strong>

              <p>At this time, INVOEASE has fewer than 20 employees and thus is exempt from California Civil Code § 1798.83.  If this changes, this privacy policy will be updated to comply with California Civil Code § 1798.83.</p>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
}

export default Privacy;