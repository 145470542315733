import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-scroll';

import {
  Container,
  Button
} from 'reactstrap';

import PageTitle from '.././common/pageTitle';

library.add(fab, fas)

function Banner(){
  return(
    <Container fluid className="banner d-flex justify-content-center align-items-center" >
      <div className="text-center">
        <PageTitle title={["AUTOMATE & STREAMLINE",  <br key={1} />, "Your Account Receivable" ]}/>

        <Link to="section1" smooth={true} offset={-70} duration={500}><Button color="yellow" size="lg" className="mt-5">Find Out How? <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></Link>
      </div>

      <div className="bottom-arrow">
        <Link to="section1" smooth={true} offset={-70} duration={500}><FontAwesomeIcon className="fa-2x" icon={['fas', 'chevron-down']} /></Link>
      </div>
    </Container>
  );
}

export default Banner;