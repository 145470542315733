import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import FlyingPlane from './flyingPlane';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {Link} from 'react-router-dom';

import {Clouds} from '../common/assets';

function StartupBusiness(){
  return(
    <Container fluid className="purple-bg pt-5 startup-business px-0">
      <Container>
        <Row className="mx-0">
          <Col md="8">
            <h5>Take Your Business</h5>
            <h2>To the next level with invoease</h2>
          </Col>

          <Col md="4" className="d-flex">
            <a href="/sign-up"><Button color="yellow" size="lg" className="align-self-center" >Start Free <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></a>
            {/* <Link to="/sign-up"><Button color="yellow" size="lg" className="align-self-center" >Start Free <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></Link> */}
          </Col>
        </Row>
      </Container>

      <FlyingPlane />
        <div className="clouds">
          <img src={Clouds} alt="clouds" className="img-fluid"/>
        </div>
    </Container>
  );
}

export default StartupBusiness;