import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';

import FooterTitle, { FooterLink, CopyRight } from './footerComponents';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import {Logo} from '../common/assets';

library.add(fab, fas)

function Footer() {
  return(
    <div>
      <Container fluid className="footer py-5 footer-bg">
        <Container>
          <Row className="mx-0">
            <Col md="3" sm="6" className="px-md-0">
              <Link to="/"><img src={Logo} alt="Logo" className="img-fluid mb-5" width="160" /></Link>
              {/* <Link to="/"><Logo className="img-fluid mb-5" width="160" /></Link> */}
              
              <FooterTitle title="Connect Socially" />
              <ul className="list-unstyled">
                <li>
                  <FooterLink href="http://fb.me/invoease" title={(<FontAwesomeIcon icon={['fab', 'facebook-square']} className="fa-2x mr-3 social-fb" />)} />

                  <FooterLink href="https://www.instagram.com/invoease_llc/" title={(<FontAwesomeIcon icon={['fab', 'instagram']} className="fa-2x mr-3 social-insta" />)} />

                  <FooterLink href="https://www.linkedin.com/company/invoease" title={(<FontAwesomeIcon icon={['fab', 'linkedin']} className="fa-2x mr-3 social-linkedin" />)} />

                  <FooterLink href="https://twitter.com/invoease_llc" title={(<FontAwesomeIcon icon={['fab', 'twitter-square']} className="fa-2x mr-3 social-twit" />)} />

                  <FooterLink href="https://www.youtube.com/channel/UCwJdu-wX49ozn_sFva0QTow" title={(<FontAwesomeIcon icon={['fab', 'youtube-square']} className="fa-2x social-youtube" />)} />
                </li>
              </ul>
            </Col>

            <Col md="3" sm="6">
              <FooterTitle title="Legal" />
              
              <ul className="list-unstyled">
                <li>
                  <Link to="/billingandsubscription">Billing & Subscription</Link>
                </li>

                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>

                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
              
            </Col>

            <Col md="3" sm="6">
              <FooterTitle title="Resources" />

              <ul className="list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/features">Features</Link>
                </li>

                <li>
                  <Link to="pricing">Pricing</Link>
                </li>

                <li>
                  <a href="mailto:admin@invoease.com">Contact</a>
                </li>
              </ul>
            </Col>

            <Col md="3" sm="6">
              <FooterTitle title="Industries" />

              <ul className="list-unstyled">
                <li>
                  <Link to="/invoease-for-it-consultant">For IT Consultant</Link>
                </li>

                <li>
                  <Link to="/invoease-for-cpa">For CPA</Link>
                </li>

              </ul>
            </Col>

          </Row>
        </Container>
      </Container>

      <Container fluid className="copyright-bg py-3">
        <Container>
          <Row className="mx-0">
            <Col className="px-0">
              <CopyRight title="© Copyright 2019. All rights reserved." />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Footer;