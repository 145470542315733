import React, { Component } from 'react';

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import "animate.css/animate.min.css";

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Navigation from './components/common/navigation';
import Home from './components/home/home';
import Pricing from './components/pricing/pricing';
import FeaturesPage from './components/features/featurespage';
import Footer from './components/common/footer';
import Signup from './components/account/signup';
import Signin from './components/account/signin';
import ForgotPass from './components/account/forgotPass';
import Billing from './components/legal/billingandsubscription';
import Privacy from './components/legal/privacy';
import Terms from './components/legal/terms';

import Landing from './components/landingPage/landing';
import LandingCPA from './components/landingCPA/landingCPA';

import DynamicScrollToTop from './components/common/scrollTop';

export default class App extends Component {
  renderWithHeaderFooter(component) {
    return <><Navigation />{component}<Footer /></>
  }

  renderWithFooter(component) {
    return <>{component}<Footer /></>
  }

  render() {
    return (  
      <div className="App">
        <BrowserRouter>
          <DynamicScrollToTop />
          <Switch>
            <Route path="/" render={props => this.renderWithHeaderFooter(<Home {...props}/>)} exact />
            <Route path="/pricing"  render={props => this.renderWithHeaderFooter(<Pricing {...props}/>)} />
            <Route path="/features"  render={props => this.renderWithHeaderFooter(<FeaturesPage {...props}/>)} />
            <Route path="/billingandsubscription"  render={props => this.renderWithHeaderFooter(<Billing {...props}/>)} />
            <Route path="/privacy"  render={props => this.renderWithHeaderFooter(<Privacy {...props}/>)} />
            <Route path="/terms"  render={props => this.renderWithHeaderFooter(<Terms {...props}/>)} />
            <Route path="/signup" component={Signup} />
            <Route path="/signin" component={Signin} />
            <Route path="/forgotPass" component={ForgotPass} />
            
            <Route path="/invoease-for-it-consultant"  render={props => this.renderWithFooter(<Landing {...props}/>)} />
            <Route path="/invoease-for-cpa"  render={props => this.renderWithFooter(<LandingCPA {...props}/>)} />
            <Route component={Error} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}


