import React from 'react';

function StepsIcon(props) {
  // const step01 = require('../../images/step-01.svg');
  return(
    <img src={props.title} alt={props.title} className="img-fluid mt-2" width="130"/>
  );
}

function StepsTitle(props) {
  return(
    <h2 className="steps-title">{props.title}</h2>
  );
}

function StepsText(props){
  return(
    <p className="steps-text">{props.title}</p>
  );
}

function StepCount(props) {
  return(
    <h1 className="stepsCount">{props.title}</h1>
  );
}

export {StepsIcon, StepsTitle, StepsText, StepCount};