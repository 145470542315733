import React from 'react';

import Banner from './banner';

import WhyInvoease from './whyInvoease';

import Steps from './steps';

import Invoices from './invoices';

import Features from './features';

import AllFeatures from './allFeatures';

import BusinessStatsSection from './businessStatsSection';

import StartupBusiness from './startupBusiness';

function Home() {
  return (
    <div>
      <Banner />
      <WhyInvoease />
      <Steps />
      <Invoices />
      <BusinessStatsSection />
      <Features />
      <AllFeatures />
      <StartupBusiness />
    </div>
  );
}
export default Home;