import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import PricingTable from './pricingTable';

import PageTitle, { PageSubTitle } from '../common/pageTitle';

import Faq from '../features/faq';

function Pricing(){
  return(
    <div>
      <Container fluid className="pricing d-flex justify-content-center align-items-center blue-bg py-5" >
        <div className="text-center my-5 py-4">
          <PageTitle title="Pricing"/>

          <PageSubTitle title="Choose a plan that best fits for your business" />
        </div>
      </Container>

      <Container fluid className="py-5 pricing-table light-bg">
        <Container>
          <Row>
            <Col>
              <PricingTable />
            </Col>
          </Row>
        </Container>
      </Container>

      <Faq/>

    </div>
  );
}
export default Pricing; 