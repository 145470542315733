import React from 'react';

function PageTitle(props) {
  return(
    <div className="page-title">
      <h1>{props.title}</h1>
    </div>
  );
}

function PageSubTitle(props) {
  return(
    <div className="page-subtitle">
      <h4>{props.title}</h4>
    </div>
  );
}

export default PageTitle;
export { PageSubTitle };