import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

function TestimonialContent(props) {
  return(
    <Container className="px-lg-4 mt-4">
      <Col className="testimonial_box px-0 pt-3 pt-md-0 mt-5">
        <Row>
          <Col md="4" lg="3">
            <img src={props.image} alt={props.image} className="img-fluid"/>
          </Col>

          <Col md="8" lg="9" className="d-flex align-items-center text-left">
            <Row>
              <Col md={{size:10, offset:1}} sm="12" className="px-5 px-md-3">
                <p>{props.text}</p>
                {/* <h5>{props.name}</h5>
                <h6>{props.company}</h6> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {/* <Col className="dropshadow">
        <img src={Dropshadow} alt="dropshadow" />
      </Col> */}
    </Container>
  );
}
export default TestimonialContent;