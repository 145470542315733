import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import ScrollAnimation from 'react-animate-on-scroll';

import SectionTitle from '.././common/sectiontitle';

import FeaturesTitle, {FeaturesText} from './featuresContent';

import { FeaturesIcon01, FeaturesIcon02, FeaturesIcon03  } from '../common/assets';

import {Feature01, Feature02, Feature03} from '../common/assets';

function Features(){
  return(
    <Container className="py-5 text-center feature">
      <SectionTitle title={['Core Features of', <br key={1}/>, 'Invoease']} color="title-dark" />

      <ScrollAnimation animateIn='fadeIn' animateOnce={ true }>
        <Row className="text-md-right text-center d-flex justify-content-center align-items-center">
          <Col lg="5" md="6">
            {/* <FeaturesIcon title="features-01" /> */}
            {/* <FeaturesIcon01 className="features-icon"/> */}
            <img src={FeaturesIcon01} alt="Easy Invoicing" className="features-icon" />
            <FeaturesTitle title="Easy Invoicing" className="title-right"/>
            {/* <FeaturesText title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
          </Col>

          <Col lg="7" md="6">
            <img src={Feature01} alt="Feature-01" className="img-responsive features-image" />
          </Col>
        </Row>
      </ScrollAnimation>

      <ScrollAnimation animateIn='fadeIn' animateOnce={ true }>
        <Row className="text-center text-md-left d-flex justify-content-center align-items-center">
          <Col lg="5" md={{size:6, order: 'last'}}>
            {/* <FeaturesIcon02 className="features-icon"/> */}
            <img src={FeaturesIcon02} alt="Quick Payments" className="features-icon" />
            <FeaturesTitle title="Quick Payments" className="title-left"/>
            {/* <FeaturesText title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
          </Col>

          <Col lg="7" md="6">
            <img src={Feature02} alt="Feature-02" className="img-responsive features-image" />
          </Col>
        </Row>
      </ScrollAnimation>
      
      <ScrollAnimation animateIn='fadeIn' animateOnce={ true }>
        <Row className="text-md-right text-center d-flex justify-content-center align-items-center">
          <Col lg="5" md="6">
            {/* <FeaturesIcon03 className="features-icon"/> */}
            <img src={FeaturesIcon03} alt="Other Features" className="features-icon" />
            <FeaturesTitle title="Other Features" className="title-right"/>
            {/* <FeaturesText title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
          </Col>

          <Col lg="7" md="6">
            <img src={Feature03} alt="Feature-03" className="img-responsive features-image" />
          </Col>
        </Row>
      </ScrollAnimation>

    </Container>
  );
}

export default Features;