import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import TestimonialContent from '.././landingPage/testimonialContent';

const items = [
  {
    image:require("./../../images/testimonials/01.jpg"),
    text:" There were Many Incidents happened where My Client was claiming that he sent me the Payment but From My End, I didn’t receive any. One of my Business Friend Told me about Invoease that it helps to Create and Send Invoice Instantly and Keeps a Track of each and every Payment Status. I immediately went to the Website and Registered in a fraction of Time. Now I am Very Happy that I found This Website which Helps me Manage all My Finances in one place. Thank you team Invoease",
    name:'John Doe',
    company:'CEO - Lorem ipsum company'
  },
  {
    image:require("./../../images/testimonials/02.jpg"),
    text:"Working as a CPA is very tough job, You Need to Handle Many Client’s Accounting At Once and it becomes very difficult to do it manually. Invoease Provided me a Platform to Manage all my Finances at one place and Gives me More time to Focus on My other Work. Invoease is a Life Saver!",
  },
  {
    image:require("./../../images/testimonials/03.jpg"),
    text:"Thank you so Much Invoease. I was making good amount of Money but it consumed 12-15 Hours/ Day due to which I couldn’t spend Time with My Family. Finally I came across Invoease which helped me to automate most of my Finances Stuffs. I’m glad I found this website Quickly.",
  },
  // {
  //   image:require("./../../images/testimonial-01.png"),
  //   text:'My Business Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   name:'John Doe',
  //   company:'CEO - Lorem ipsum company'
  // },
  
];

export default class TestimonialCPA extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    const { activeIndex } = this.state;
    
    const slides = items.map((item, key) => {
      return (
        <CarouselItem key={key}>
          {/* <TestimonialContent image={item.image} text={item.text} name={item.name} company={item.company} /> */}
          <TestimonialContent image={item.image} text={item.text} />
        </CarouselItem>
      );
    });

    return (      
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}          
      >
        
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    
    );
  }
}