import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Navigation from '.././landingPage/landingNavbar';

import SectionTitle from '.././common/sectiontitle';

import WhyInvoease from '.././home/whyInvoease';

import LandingBanner from '.././landingPage/landingBanner';
import LandingFeatures from '.././landingPage/landingFeatures';
import InvoiceSlider from '.././landingPage/invoiceSlider';
import LoveInvoeaseCPA from './loveInvoeaseCPA';

import TestimonialCPA from './testimonialCPA';

import FaqLanding from '.././landingPage/faqLanding';

import StartupBusiness from '.././home/startupBusiness';

import BusinessStats from '.././common/businessStats';

function LandingCPA(){
  return(
    <div>

      {/* Navigation Menu */}
      <Navigation />

      {/* Banner */}
      <div id="banner">
        <LandingBanner 
          background="landingCpaBG"
          subtitleLineOne="One Stop Financing Solution For all The IT Staffing"
          subtitleLineTwo="and Consulting Firms"
        />
      </div>

      {/* Why Love Invoease Section */}
      <WhyInvoease />

      {/* Features */}
      <div id="features">
        <LandingFeatures />
      </div>

      {/* Signup section */}
      <Container fluid className="blue-bg py-5 startup-business">
        <Container>
          <Row className="mx-0">
            <Col md="8" className="d-flex align-items-center">
              <h2>Send your First Invoice Today, It's Free</h2>
            </Col>

            <Col md="4" className="d-flex">
              <a href="/sign-up"><Button color="yellow" size="lg" className="align-self-center" >Start Free <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></a>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Why Love using invoease */}
      <Container fluid className="py-5 text-center" id="whyInvoease">
        <SectionTitle title={["Why CPA's", <br key={1}/>, "Love Using Invoease?"]} color="title-dark" />
        <LoveInvoeaseCPA />
      </Container>

      {/* Invoice Slider */}
      <Container fluid className="py-5 light-bg text-center invoice_slider" id="professionalInvoice">
        <SectionTitle title={['Invoice that Suits', <br key={1}/>, 'Your Brand']} color="title-dark" />
        <Container className="overflow-hidden pb-5">
          <Row>
            <Col>
              <InvoiceSlider />
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Business Stats */}
      <Container fluid className="pt-5">
        <BusinessStats />

        {/* Signup section */}
        <Container className="py-5 get_started mt-5">
          <Row className="mx-0">
            <Col md="8" className="d-flex align-items-center">
              <h2>Ready to Get Started?</h2>
            </Col>

            <Col md="4" className="d-flex">
              <a href="/sign-up"><Button color="yellow" size="lg" className="align-self-center" >Start Free <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></a>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Testimonials */}
      <Container fluid className="blue-bg py-5 text-center" id="testimonial">
        <Container className="overflow-hidden">
          <TestimonialCPA />
        </Container>
      </Container>
    
      {/* Frequently asked questions */}
      <FaqLanding/>

      {/* Signup Section */}
      <StartupBusiness />

    </div>
  );
}
export default LandingCPA; 