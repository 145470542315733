import React from 'react';

function FeaturesTitle(props) {
  return(
    <h2 className={("features-title " + props.className)}>{props.title}</h2>
  );
}

function FeaturesText(props) {
  return(
    <p className="features-text">{props.title}</p>
  );
}

function Category(props){
  return(
    <h4 className={("features-category")}>{props.title}</h4>
  );
}

function FeatureLink(props){
  return(
    // <a href={props.href || "#"} className={("features-link")}>{props.title}</a>
    <p className={("features-link")}>{props.title}</p>
  );
}

// Features Main Page

function TopicTitle(props){
  return(
    <h5 className={("topic-title")}>{props.title}</h5>
  );
}

function TopicText(props){
  return(
    <p className={("topic-text")}>{props.title}</p>
  );
}

function ReadMore(props){
  return(
    <a href={props.href || "#"} className={("read-more")}>Read More</a>
  );
}

export default FeaturesTitle;
export {FeaturesText, Category, FeatureLink, TopicTitle, TopicText, ReadMore};