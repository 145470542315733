import React from 'react';

import {
  Container
} from 'reactstrap';

import Terms from './termsSection';

import PageTitle from '../common/pageTitle';

function Home(){
  return (     
    <div>
      <Container fluid className="d-flex justify-content-center align-items-center blue-bg py-5" >
        <div className="text-center my-5 py-5 small-title">
          <PageTitle title="Terms and Conditions"/>
        </div>
      </Container>
      
      <Terms />
    </div>        
  );
}

export default Home;