import React from 'react';

function FooterTitle(props) {
  return(
    <h2 className="footer-title">{props.title}</h2>
  );
}

function FooterLink(props) {
  return(
    <a href={props.href} className="footer-link" target="_blank">{props.title}</a>
  );
}

function CopyRight(props) {
  return(
    <p className="copyright">{props.title}</p>      
  );
}

export default FooterTitle;
export {FooterLink, CopyRight};