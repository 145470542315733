import React from 'react';

import {StepsIcon, StepsTitle, StepsText, StepCount} from './stepscontent';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import {Step01, Step02, Step03} from '../common/assets';

function Steps(){
  return(
    <Container className="my-5 py-5" id="section1">
      <Row className="mx-0">
        <Col md="4" className="stepContainer py-3 px-4 text-center orange">
          <StepsIcon title={Step01} />
          {/* <Step01 className="steps-icon mt-2" /> */}

          <StepsTitle title={["Access From", <br key={1}/>, " Anywhere"]} />

          <StepsText title="Invoease offers an easy-to-use online payment and invoicing Platform. It lets you create and send electronic invoices to your customers, receive payments, send estimates and timesheets management from your employees, as well as track and receive electronic deposits. Since it is online and mobile, you can do all of this almost wherever, whenever it is convenient." />

          <StepCount title="1"/>
        </Col>

        <Col md="4" className="stepContainer py-3 px-4 text-center blue">
          
          <StepsIcon title={Step02} />
          {/* <Step02 className="steps-icon mt-2" /> */}
          
          <StepsTitle title={["Send", <br key={1}/>, " Invoices"]} />

          <StepsText title="At Invoease we try to simplify invoicing and take greater control of your cash flow with online invoicing built for businesses of any size. Automation helps you invoice on time. Discount terms help you get paid early. And Mobile apps help you simply squeeze more time out of your day." />

          <StepCount title="2"/>
        </Col>

        <Col md="4" className="stepContainer py-3 px-4 text-center green">
          {/* <StepsIcon title="step-03"/> */}
          {/* <Step03 className="steps-icon mt-2" /> */}
          <StepsIcon title={Step03} />

          <StepsTitle title={["Get", <br key={1}/>, "Paid"]} />

          <StepsText title="Track and instantly know the status of an invoice or payment requested to your customers through Invoease online invoice and save time. Get details of cash inflows, outflows and opportunities for early payment discounting through Invoease. That’s how being connected to your customers on Invoease can help you make business decisions with more confidence." />

          <StepCount title="3"/>
        </Col>
      </Row>
    </Container>
  );
}

export default Steps;