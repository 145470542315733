import React from 'react';

function FaQues(props) {
  return(
    <h4 className="fa-ques">{props.title}</h4>
  );
}

function FaAns(props) {
  return(
    <p className="fa-ans">{props.title}</p>
  );
}

export default FaQues;
export { FaAns };