import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import SectionTitle from '.././common/sectiontitle';

import FaQues, { FaAns } from '.././features/faqContent';


function FaqLanding(){
  return(
    <Container className="py-5 text-center">
      <SectionTitle title="Frequently Asked Questions?" />

      <Row className="text-left">

        <Col md="4" className="mb-4 mb-md-0">
          <div className="faq_box">
            <FaQues key="q1" title="Is Invoease completely free of cost?" />
            <ul>  
              <li>
                <FaAns key="a1_1" title="We have 3 Seperate Plans." />
              </li>
              <li>
                <FaAns key="a1_2" title="Forever Free Plan Allows you to manage 5 Clients at a Time and Send Unlimited Invoices" />
              </li>
              <li>
                <FaAns key="a1_3" title="Startup Plan Allows you to manage 50 Clients at a Time and Send Unlimited Invoices" />
              </li>
              <li>
                <FaAns key="a1_4" title="Business Plan Allows you to manage unlimited Clients at a Time and Send Unlimited Invoices" />
              </li>
              <li>
                <FaAns key="a1_5" title="The Best Part is, You Don’t need to pay a single penny to get started. Upgrade the Plan as you grow." />
              </li>
            </ul>
          </div>
        </Col>

        <Col md="4" className="mb-4 mb-md-0">
          <div className="faq_box">
            <FaQues key="q2" title="Do I need a credit card?" />
            <ul>  
              <li>
                <FaAns key="a2_1" title="No, You don’t need any Credit Card to Subscribe for the Free Plan. You Need to Only use your Debit/Credit Card while upgrading your plan" />
              </li>
            </ul>
          </div>
        </Col>

        <Col md="4">
          <div className="faq_box">
            <FaQues key="q3" title="Does Invoease work on my Mac, PC and mobile device?" />
            <ul>  
              <li>
                <FaAns key="a3_1" title="Yes, Invoease works in all the Devices. Whether it is PC or MAC or Mobile." />
              </li>
            </ul>
          </div>
        </Col>
       
      </Row>

    </Container>
  );
}

export default FaqLanding;