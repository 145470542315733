import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

import TestimonialContent from './testimonialContent';

const items = [
  {
    image:require("./../../images/testimonials/04.jpg"),
    text:"I just wanted to share a quick note and let you know that you guys do a really good job. Invoease's Interface is really easy to use. I never Got any issue while using your Platform. Few months Back, I was doing all Finance Work Manually which was stoping me to Go to Next Level. I'm very Glad that I found Invoease Manange all my Finances. The Templates which Invoease provides is very Professional and appealing which helped me Many times to Get Paid From My Clients Instantly. Thank you Team Invoease.",
    name:'John Doe',
    company:'CEO - Lorem ipsum company'
  },
  {
    image:require("./../../images/testimonials/05.jpg"),
    text:"Invoease Helped to Grow My Business 3X and Helped me to Automate most of My work which Gives me More Time to Spend with My Family. Lots of Love!",
  },
  {
    image:require("./../../images/testimonials/06.jpg"),
    text:"Invoease Interface is too easy too use. First I was thinking whether I should use it or not as I am Not Good in terms of Finances. But I'm Glad I took Action and Registered into this platform which makes my work a lot Easier. Thank you So Much for your Support",
  },
  // {
  //   image:require("./../../images/testimonial-01.png"),
  //   text:'My Business Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   name:'John Doe',
  //   company:'CEO - Lorem ipsum company'
  // },
  
];

export default class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    const { activeIndex } = this.state;
    
    const slides = items.map((item, key) => {
      return (
        <CarouselItem key={key}>
          {/* <TestimonialContent image={item.image} text={item.text} name={item.name} company={item.company} /> */}
          <TestimonialContent image={item.image} text={item.text} />
        </CarouselItem>
      );
    });

    return (      
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}          
      >
        
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    
    );
  }
}

