import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import FeaturesTitle, {FeaturesText} from '../home/featuresContent';

import AccountForm from './forgotPassForm';

import { Link } from 'react-router-dom';

import FaQues from '../features/faqContent'

import {ForgotPassBg} from '../common/assets';

import { LogoDark } from '../common/assets';

function ForgotPass(){
  return(
    <div>
      <Container fluid className="py-5 account h-100 position-fixed">

        <Container>
          <Row>
            <Col>
              <Link to="/"><img src={LogoDark} alt="Logo" className="img-fluid mb-5" width="160" /></Link>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FeaturesTitle title="Forgot Password?" className="no-border" />

              
            </Col>
          </Row>

          <Row className="sign-up">
            <Col md="6">
              <Row className="d-flex align-items-center h-100">
                <Col>
                  <Row>
                    <Col className="mb-3">
                      <FeaturesText title="Enter your email address that you used to register. We’ll send you an email with your username and a link to reset your password."/>
                    </Col>
                  </Row>

                  <AccountForm />

                  <FeaturesText title={["If you still need help, contact ", <a href="mailto:admin@invoease.com" key="Invoease Support" className="need-help mb-3">Invoease Support</a>]} />

                  <FeaturesText title={["Back to ", <a href="/Signin" key="Signin" className="need-help">Login</a>]} />                  
                </Col>
              </Row>
            </Col>
          
            <Col md="6" className="mt-4 mt-md-0">
              <div className="account_slider px-5 h-100 d-flex align-items-center justify-content-center py-3 text-center">
                <div>
                  <img src={ForgotPassBg} className="img-fluid mx-auto" alt="signin background"/>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-center pt-5">
              <FaQues title={[<Link to="/signin" key="sign-in">Sign in</Link>, " OR ", <Link to="/signup" key="signup">Sign up</Link>]} />

              <Row className="my-3">
                <Col lg={{size:'6', offset:'3'}} md="12" className="d-flex">
                  <span className="full-seperator"></span>
                </Col>
              </Row>

              <FeaturesText title={["By continuing you are agreeing to our ", <Link to="/billingandsubscription" key="billingandsubscription">Billing & Subscription</Link>, ", ", <Link key="privacy" to="/privacy">Privacy Policy</Link>, " and " , <Link key="terms" to="/terms">Terms Of Service</Link>]} />

            </Col>
          </Row>

        </Container>
      </Container>
    </div>
  );
}

export default ForgotPass;