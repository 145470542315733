import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import SectionTitle from '.././common/sectiontitle';

import FaQues, { FaAns } from './faqContent';


function Faq(){
  return(
    <Container className="py-5 text-center">
      <SectionTitle title="Questions?" />

      <Row className="text-left">

        <Col md="6" className="mb-4 mb-md-0">
            <FaQues key="q1" title="I want to try Invoease, should i sign up for the free plan or one of the paid plans?" />
            <FaAns key="a1" title="You should sign up with free plan, and once you’re happy using inverse you can upgrade your plan anytime from Settings > Account page." />
        </Col>

        <Col md="6" className="mb-4 mb-md-0">
            <FaQues key="q2" title="When will my card get charged for a paid plan?" />
            <FaAns key="a2" title="Your billing date will depend the date you subscribed as well as the frequency cycle selected (i.e. monthly or yearly). You can view your next payment date via the Setting > Account page." />
        </Col>

        <Col md="6" className="mb-4 mb-md-0">
            <FaQues key="q3" title="What forms of payments are accepted?" />
            <FaAns key="a3" title="Invoease accepts all major credit / debit cards. Our billing partner Stripe handles all payment request and ensures that your card details are safe and secure." />
        </Col>

        <Col md="6" className="mb-4 mb-md-0">
            <FaQues key="q4" title="What if my credit / debit card is not accepted?" />
            <FaAns key="a4" title="If you get an error message when entering your card information please contact us at admin@invoease.com" />
        </Col>

        <Col md="12" className="mt-5 text-center">
            <h4>Have more questions? Feel free to <a href="mailto:admin@invoease.com">write to us.</a></h4>
        </Col>

      </Row>

    </Container>
  );
}

export default Faq;