import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import PageTitle, {PageSubTitle} from '.././common/pageTitle';

library.add(fab, fas)

function LandingBanner(props){
  return(
    <Container fluid className={props.background + " banner d-flex justify-content-center align-items-center px-0"} >
      <Row className="text-center bg-overlay w-100 pt-4 pb-5">
        <Col>
          <PageTitle title={["Easy Invoice"]}/>

          <PageSubTitle title={[props.subtitleLineOne, <br key={1} />, props.subtitleLineTwo]} />
          <a href="/sign-up"><Button color="yellow" size="lg" className="mt-5" >Start Free <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></a>
        </Col>
      </Row>
    </Container>
  );
}

export default LandingBanner;