import React from 'react';
import { 
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AccountForm() {
  return (
    <Form>
      <FormGroup>
        <Input type="text" name="fullName" id="fullName" placeholder="Full Name" />
      </FormGroup>

      <FormGroup>
        <Input type="text" name="company" id="company" placeholder="Company" />
      </FormGroup>

      <FormGroup>
        <Input type="email" name="email" id="email" placeholder="Email" />
      </FormGroup>

      <FormGroup>
        <Input type="password" name="password" id="password" placeholder="Password" />
      </FormGroup>
      
      <Button color="blue" size="lg" className="py-3 w-100">Start Now <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button>
    </Form>
  );
}
export default AccountForm;