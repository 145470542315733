import React from 'react';
import {Container} from 'reactstrap';
import BusinessStats from '.././common/businessStats';

function BusinessStatsSection(){
  return(
    <Container fluid className="light-bg py-5">
      <BusinessStats />
    </Container>
  );
}

export default BusinessStatsSection;