// Images for Invoices Section
export const Invoice01 = require('./../../images/invoice-01.png');
export const Invoice02 = require('./../../images/invoice-02.png');
export const Invoice03 = require('./../../images/invoice-03.png');
export const Invoice04 = require('./../../images/invoice-04.png');
export const Invoice05 = require('./../../images/invoice-05.png');

// Images for Features icon of Invoease Section
export const Feature01 = require('./../../images/feature-01.png');
export const Feature02 = require('./../../images/feature-02.png');
export const Feature03 = require('./../../images/feature-03.png');

export const FeatureBg01 = require('./../../images/feature-bg-01.png');
export const FeatureBg02 = require('./../../images/feature-bg-02.png');
export const FeatureBg03 = require('./../../images/feature-bg-03.png');

// Images for Account
export const SigninBg = require('./../../images/signin.png');
export const SignupBg = require('./../../images/signup.png');
export const ForgotPassBg = require('./../../images/forgot-pass.png');

// Images for Why Invoease
export const WhyInvoeaseBg = require('./../../images/why-invoease.jpg');

// Images for Testimonials
// export const Dropshadow = require('./../../images/dropshadow.png');

// Images for Flying plane section
export const Clouds = require('./../../images/clouds.png');

// Images for Features icon of Invoease Section
export const FeaturesIcon01 = require('./../../images/features-01.svg');
export const FeaturesIcon02 = require('./../../images/features-02.svg');
export const FeaturesIcon03 = require('./../../images/features-03.svg');

// Icons for Steps section
export const Step01 = require('./../../images/step-01.svg');
export const Step02 = require('./../../images/step-02.svg');
export const Step03 = require('./../../images/step-03.svg');

// Check icon for pricing table
export const Check = require('./../../images/check.svg');
export const Dash = require('./../../images/dash.svg');

// Images for Account
export const FacebookIcon = require('./../../images/social-fb.svg');
export const GoogleIcon = require('./../../images/social-g.svg');
export const LinkedinIcon = require('./../../images/social-in.svg');

// Logo for Footer
// {ReactComponent as Logo} from './../../images/invoease.svg';
export const Logo = require('./../../images/invoease.svg');
export const LogoDark = require('./../../images/invoease-dark.svg');

// Images for Landing Page Features icon of Invoease Section
export const LandingFeatures01 = require('./../../images/landing_icons/landing_features-01.svg');
export const LandingFeatures02 = require('./../../images/landing_icons/landing_features-02.svg');
export const LandingFeatures03 = require('./../../images/landing_icons/landing_features-03.svg');
export const LandingFeatures04 = require('./../../images/landing_icons/landing_features-04.svg');
export const LandingFeatures05 = require('./../../images/landing_icons/landing_features-05.svg');
export const LandingFeatures06 = require('./../../images/landing_icons/landing_features-06.svg');

//Images for Invoice slider
export const InvoiceSlide1 = require('./../../images/invoice-03.png');
export const InvoiceSlide2 = require('./../../images/invoice-04.png');
export const InvoiceSlide3 = require('./../../images/invoice-05.png');


// Business Stats Icon
export const BusinessStats1 = require('./../../images/businessStats1.svg');
export const BusinessStats2 = require('./../../images/businessStats2.svg');
export const BusinessStats3 = require('./../../images/businessStats3.svg');

// export {FeaturesIcon01, FeaturesIcon02, FeaturesIcon03, Step01, Step02, Step03, Logo, Invoice01, Invoice02, Invoice03, Invoice04, Invoice05, Feature01, Feature02, Feature03, FeatureBg01, FeatureBg02, FeatureBg03, SigninBg, SignupBg, Dropshadow, Clouds};
