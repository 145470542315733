import React from 'react';

function FlyingPlane(){
  return(
    <svg 
      // width="1461px" height="205px"
      width="100%" height="100%" viewBox="0 -30 1370 250">
      {/* <path fillRule="evenodd" className="path" stroke="rgba(255, 255, 255, 0.2)" strokeWidth="4px" strokeDasharray="16, 8" id="motionPath" strokeLinecap="butt" fill="none"
      d="M2.000,198.000 C2.000,198.000 218.572,11.877 464.000,103.000 C709.428,194.123 911.723,196.632 910.000,84.000 C910.000,84.000 894.538,21.843 834.000,45.000 C834.000,45.000 776.825,75.483 816.000,127.000 C855.647,179.136 1004.359,208.116 1100.000,159.000 C1230.220,92.126 1261.068,8.141 1460.000,2.000 "/> */}

      <path fillRule="evenodd" className="path" stroke="rgba(255, 255, 255, 0.2)" strokeWidth="4px" strokeDasharray="16, 8" id="motionPath" strokeLinecap="butt" fill="none"
      d="M0,196c0,0,216.6-186.1,462-95c230.8,85.7,423.4,93,444.2-0.1c2.7-12.2,2.4-26-3.8-36.9  c-8.1-14-25.5-31.9-54.1-27.5c-10.9,1.7-22,5.1-30.2,12.5c-14.1,12.7-30.5,41.2-4.1,76c39.6,52.1,188.4,81.1,284,32  C1228.2,90.1,1256.1,7.2,1455,1.1"/>
              
      <path id="plane" fill="rgb(255, 255, 255)" transform="translate(-30,-30)" d="M51.2,34.9c3.5-0.5,6.4-1.7,7.9-3.2c0.5-0.5,0.8-0.9,0.9-1.4c0-0.1,0.1-0.2,0-0.4c0-0.2,0-0.3,0-0.3   c-0.1-0.5-0.4-1-0.9-1.4c-1.5-1.5-4.4-2.6-7.9-3.2c-2.8-0.4-6.1-0.3-10.7-0.2c-0.8,0-1.6,0-2.5,0.1l-3-5.6l0,0c0.4,0,0.7-0.1,1-0.4   c0.3-0.3,0.4-0.6,0.4-1l0-1.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4l-2.3,0l-0.4-0.8c0.3,0,0.6-0.2,0.8-0.4   c0.3-0.3,0.4-0.6,0.4-1v-1.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4l-2.1,0l-3.1-5.7c-0.1-0.1-0.2-0.2-0.3-0.4   c-0.3-0.3-0.7-0.5-1.1-0.5l-3.1,0c-0.4,0-0.7,0.1-0.9,0.4C21.1,4.1,21,4.6,21.2,5l4.1,14.4c0,0.1,0.1,0.3,0.1,0.4l0,5.3   c-5.1,0.1-9.8,0.5-13.7,0.9c-0.4,0-0.7,0.1-1.1,0.1l-6.5-7.8c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.8-0.5-1.3-0.5l-1.4,0   c-0.4,0-0.7,0.1-0.9,0.4c-0.4,0.3-0.5,0.9-0.3,1.3l2.6,7.4c0,0.1,0.1,0.3,0.1,0.3l0,0.8c-0.5,0.2-0.9,0.5-1.2,0.8   C1.2,29.1,1,29.5,1,30l0,0l0,0c0,0.5,0.2,0.9,0.6,1.3c0.3,0.3,0.7,0.5,1.2,0.8l0,0.8c0,0.1,0,0.3-0.1,0.3l-2.6,7.4   c-0.2,0.5-0.1,1,0.3,1.3l0,0c0.2,0.2,0.6,0.4,0.9,0.4l1.5,0c0.4,0,0.9-0.2,1.2-0.5c0,0,0.1-0.1,0.1-0.1l6.5-7.8   c0.3,0,0.7,0.1,1.1,0.1c3.9,0.5,8.6,0.8,13.6,0.9l0,5.3c0,0.1,0,0.3-0.1,0.4L21.2,55c-0.1,0.5,0,0.9,0.3,1.3   c0.2,0.2,0.6,0.4,0.9,0.4l3.1,0c0.4,0,0.8-0.2,1.1-0.5c0.1-0.1,0.2-0.2,0.3-0.3l3.1-5.7l2.1,0c0.4,0,0.7-0.1,1-0.4   c0.3-0.3,0.4-0.6,0.4-1l0-1.6c0-0.4-0.1-0.7-0.4-1c-0.2-0.2-0.5-0.4-0.8-0.4l0.4-0.8H35c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1   v-1.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4l0,0l3-5.6c0.9,0,1.7,0,2.5,0.1C45.1,35.3,48.4,35.3,51.2,34.9z"/>
      
        <animateMotion 
            xlinkHref="#plane"
            dur="17s"
            begin="0s"
            fill="freeze"
            rotate="auto"
            repeatCount="indefinite"
          >
          <mpath
            xlinkHref="#motionPath"
          />
        </animateMotion>
      </svg>
  );
}

export default FlyingPlane;