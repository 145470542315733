import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import FeaturesTitle, { FeaturesText } from '../home/featuresContent';

import AccountForm from './signupForm';

import FaQues from '../features/faqContent'

import { Link } from 'react-router-dom';

import {SignupBg} from '../common/assets';

import { LogoDark, FacebookIcon, GoogleIcon, LinkedinIcon } from '../common/assets';

function Signup(){
  return(
    <div>
      <Container fluid className="py-5 account">
        <Container>
          <Row>
            <Col>
              <Link to="/"><img src={LogoDark} alt="Logo" className="img-fluid mb-5" width="160" /></Link>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col>
              <FeaturesTitle title="Get Started" className="no-border" />

              <FeaturesText title="Signup with"/>
            </Col>
          </Row>

          <Row className="sign-up">
            <Col md="6">
              <Row>
                <Col>
                  <a href="https://www.facebook.com/signup" target="blank" className="account-fb"><img src={FacebookIcon} width="34" /></a>
                </Col>

                <Col>
                  <a href="https://accounts.google.com/signup/v2/webcreateaccount?continue=https%3A%2F%2Fwww.google.com%2F&hl=en&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp" target="blank" className="account-google"><img src={GoogleIcon} width="34"/></a>
                </Col>

                <Col>
                  <a href="https://www.linkedin.com/start/join?trk=guest_homepage-basic_nav-header-join" target="blank" className="account-in"><img src={LinkedinIcon} width="34" /></a>
                </Col>
              </Row>

              <Row className="my-4">
                <Col>
                  <span className="seperator">OR</span>
                </Col>
              </Row>

              <Row >
                <Col>
                  <AccountForm />
                </Col>
              </Row>
            </Col>
          
            <Col md="6" className="mt-4 mt-md-0">
              <div className="account_slider px-5 h-100 d-flex align-items-center py-3 py-md-0 text-center">
                <div>
                  <img src={SignupBg} className="img-fluid mb-4" alt="signup background"/>

                  <FeaturesTitle title="Welcome!" className="no-border" />

                  <FeaturesText title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-center pt-5">
              <FaQues title={["Already have an account? ", <Link to="/signin" key="signin">Log in</Link>]} />

              <Row className="my-3">
                <Col lg={{size:'6', offset:'3'}} md="12" className="d-flex">
                  <span className="full-seperator"></span>
                </Col>
              </Row>

              <FeaturesText title={["By continuing you are agreeing to our ", <Link to="/billingandsubscription" key="billingandsubscription">Billing & Subscription</Link>, ", ", <Link key="privacy" to="/privacy">Privacy Policy</Link>, " and " , <Link key="terms" to="/terms">Terms Of Service</Link>]} />

            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Signup;