import React from 'react';

import { 
    Container,
    Row,
    Col,
} from 'reactstrap';

import FeaturesTitle from '.././home/featuresContent';

import { Link } from 'react-router-dom';

function BillingSubscription() {
  return (
    <Container className="py-5">
      <Row>
        <Col className="text-left terms">
          <p>By checking the box indicating “I agree to the Billing Agreement,” You enter into and agree to be bound by the terms of this Billing and Subscription Agreement (“Agreement”) between You and INVOEASE LLC (“INVOEASE”), an Illinois limited liability company.  (INVOEASE and You may be collectively referenced herein as the “parties,” or individually as a “party.”)</p>
        </Col>
      </Row>

      <FeaturesTitle title="Recitals" className="title-left no-border mt-5"/>

      <Row>
        <Col className="text-left terms mt-3">
          <p><strong>WHEREAS</strong>, You desire to subscribe to INVOEASE’s application, software, and website for sending Invovice or Estimates to your customer and/or Allowing your employee use timesheets from our platform (“INVOEASE’s Service”);</p>

          <p><strong>WHEREAS</strong>, INVOEASE desires to allow You to subscribe to INVOEASE’s Service on the terms set forth herein;</p>

          <p><strong>NOW, THEREFORE</strong>, in consideration of the mutual covenants and promises set forth in this Agreement, and other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows:</p>
        </Col>
      </Row>

      <FeaturesTitle title="Agreement" className="title-left no-border mt-5"/>

      <Row>
        <Col className="text-left terms mt-3">
          <ol>
            <li><strong>Incorporation of Recitals.</strong> The recitals set forth above are hereby incorporated into and made a part of this Agreement as though set forth fully herein.</li>

            <li>
              <strong>Subscription.</strong> You hereby agree to subscribe to INVOEASE’s Service, which includes access to and use of the applications and websites which comprise INVOEASE’s Service.  You may subscribe to either the Free, Start-up or Business, described herein (either referred to herein as “Your Subscription”).  Your Subscription is subject to (a) all other terms and conditions set forth in this Agreement, and (b) the Terms and Conditions of INVOEASE’s Service, as may be modified from time to time by INVOEASE, which are available at <Link to="/terms">Terms & Conditions</Link> and which are hereby incorporated by reference into this Agreement.

              <ul>
                <li><strong>Subscription Term.</strong> The length of Your Subscription is thirty (30) days.</li>

                <li><strong>Renewal.</strong> Your Subscription will automatically renew every thirty (30) days until terminated in accordance with section 8 of this Agreement.</li>

                <li>Each 30-day period of your subscription term or of any renewal may be referred to herein as a “Subscription Month.”</li>
              </ul>
            </li>

            <li>
              <p><strong>Benefits of Subscription Packages.</strong> INVOEASE offers the following subscription packages, with the respective benefits of each as follows:</p>
              
              <p><strong>FREE:</strong> The free package includes:</p>
              <ul>
                <li>Single User</li>
                <li>Unlimited Storage</li>
                <li>Unlimited Estimates</li>
                <li>Create or Add Limited clients</li>
                <li>Dedicated Phone Support</li>
                <li>Multi-payment Processing</li>
                <li>ACH Payments</li>
              </ul>

              <p><strong>Start-Up:</strong> The Start-Up package includes:</p>
              <ul>
                <li>5 User</li>
                <li>Unlimited Storage</li>
                <li>Unlimited Estimates</li>
                <li>Create or Add Limited clients</li>
                <li>Dedicated Phone Support</li>
                <li>Multi-payment Processing</li>
                <li>ACH Payments</li>
                <li>Custom Invoice</li>
                <li>Your Branded Invoice</li>
                <li>Monthly Status Reports</li>
              </ul>

              <p><strong>Business:</strong> The Business package includes:</p>
              <ul>
                <li>Unlimited User</li>
                <li>Unlimited Storage</li>
                <li>Unlimited Estimates</li>
                <li>Create or Add Limited clients</li>
                <li>Dedicated Phone Support</li>
                <li>Multi-payment Processing</li>
                <li>ACH Payments</li>
                <li>Custom Invoice</li>
                <li>Your Branded Invoice</li>
                <li>Monthly Status Reports</li>
                <li>Employee timesheets up-to 10 employees included</li>
              </ul>

              <p>
                <strong>No Refunds.</strong> Invoease will not issue refunds or credits of any kind for unused Plans or other allocation benefits.
              </p>

              <p>
                <strong>Purchase of additional Employees for timesheets.</strong> In the event you use all of your allocated timesheets employee count during your Subscription Month, you can purchase more for use during that Subscription Month (“Additional Verifications”).  Additional Verifications does expire at the end of the Subscription Month in which they are purchased unless removed by the plan by subscriber. INVOEASE will not issue refunds or credits of any kind for unused Employee count for Timesheet.  Purchases of Additional Employees count for timesheet does automatically recur or renew from month to month.  You must reduce or add Additional employees through the website each month you require Additional employees for the payroll month, and you will not be charged for Additional Verifications unless you do so.
              </p>
            </li>

            <li>
              <p><strong>Payments; Automatic Payment Method.</strong></p>

              <ul>
                <li><strong>Payment amount.</strong> In exchange for Your Subscription, You agree to make a monthly payment to INVOEASE in the amount of $14.99 for Start-Up or $24.99 for Business Plan Subscription.</li>

                <li><strong>Automatic payment method.</strong> You agree to provide INVOEASE with a credit card or other payment information which INVOEASE can use to obtain Your monthly payment at the time of automatic renewal.  You agree to provide INVOEASE with all authorizations needed for INVOEASE to obtain the withdrawal of Your monthly payment without further approval from You.  You further agree to provide INVOEASE with updated payment information in the event You close the account associated with the initial payment information provided, fail to maintain sufficient funds in such account, or commit such other actions or omissions that prevent INVOEASE from obtaining the monthly payment from such account.</li>
                
                <li><strong>Changes in payment amount.</strong> You agree that INVOEASE shall have the right to change the payment amounts set forth in section 4(a) without advanced notice to You.</li>
              </ul>
            </li>

            <li>
              <strong>INVOEASE’s Rights in Event of Non-Payment.</strong> If INVOEASE is unable for any reason to obtain the monthly payment amount due from the payment information provided by You, INVOEASE shall issue written notice to the email address affiliated with You stating that INVOEASE was unable to process Your monthly payment, and that if You do not correct the failed payment within five (5) business days, INVOEASE will have the right to terminate Your Subscription.  You then have five (5) business days following the issuance of such notice to cure the non-payment, and if necessary, provide new payment information in order to avoid having Your Subscription terminated.
            </li>

            <li><strong>Refund Policy.</strong> INVOEASE will issue a refund only (a) in the event that Your account is mistakenly overcharged or charged repeatedly for the same monthly payment, provided that any overcharge is reflected in a difference between the monthly payment then in effect for Your Subscription and the amount charged to Your payment account, or (b) for any reason not set forth herein that is required under the statutes or administrative rules in your jurisdiction.  INVOEASE will NOT be obligated to issue a refund in any other circumstances, including but not limited to if You are dissatisfied with Your Subscription, if You suffer an outage as described in section 9(a), or if You do not use Your Subscription or any benefits thereof.</li>

            <li><strong>Credit Information Check.</strong> YOU AUTHORIZE US TO OBTAIN CREDIT BUREAU REPORTS, AND MAKE OTHER CREDIT INQUIRIES WE DETERMINE ARE NECESSARY.  ON YOUR WRITTEN REQUEST, WE WILL INFORM YOU WHETHER WE HAVE REQUESTED A CONSUMER CREDIT REPORT AND THE NAME AND ADDRESS OF ANY CONSUMER CREDIT REPORTING AGENCY THAT FURNISHED A REPORT. YOU ACKNOWLEDGE WITHOUT FURTHER NOTICE THAT WE MAY USE OR REQUEST ADDITIONAL CREDIT BUREAU REPORTS TO UPDATE OUR INFORMATION.</li>

            <li>
              <p><strong>Termination.</strong></p>
              <ul>
                <li>
                  <strong>Termination by You.</strong> You may terminate the Agreement, with or without cause, upon written notice provided to INVOEASE no less than seven (7) days’ prior to your automatic renewal date, however the termination shall not become effective until the first automatic renewal date at or following the expiration of seven (7) days following the issuance of the written notice.
                </li>
                <li>
                  <strong>Termination by INVOEASE.</strong> Except as provided in section 5 of this Agreement, INVOEASE may terminate the Agreement with or without cause, effective immediately upon written notice provided to You.
                </li>
              </ul>
            </li>

            <li>
              <p><strong>Disclaimers.</strong></p>
              <ul>
                <li>Notwithstanding the foregoing, INVOEASE does not guarantee network availability between You and any hosting servers, as such availability of Your Subscription can involve third parties and is beyond the control of INVOEASE.  INVOEASE will not be liable for, nor provide refunds or credits under this Agreement for any downtime caused in whole or in part by an internet service provider or other third party.  If You are unable to access Your Subscription, then You must immediately contact INVOEASE, providing all necessary information that may assist INVOEASE in determining the cause of the outage.  INVOEASE will determine in good faith whether the outage is or was within INVOEASE’s reasonable control, and remedy the outage if necessary.</li>
              </ul>
            </li>

            <li>
              <strong>Limited Warranty; Disclaimer of Other Warranties.</strong> INVOEASE’S SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND. INVOEASE HEREBY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT; AVAILABILITY OF INVOEASE’S SERVICE; LACK OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER CODE THAT MANIFESTS CONTAMINATING OR DESTRUCTIVE PROPERTIES; ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, CURRENCY, OR USEFULNESS OF ANY INFORMATION THROUGH INVOEASE’S SERVICE—INCLUDING BUT NOT LIMITED TO ANY INFORMATION THAT IS SUBMITTED BY ANY CUSTOMER OF YOURS AND ANY TYPOGRAPHICAL ERRORS BY INVOEASE OR ANY OTHER USER; AND ANY DUTIES OF REASONABLE CARE, WORKMANLIKE EFFORT OR LACK OF NEGLIGENCE IN CONNECTION WITH INVOEASE’S SERVICE, CONTENT AND MATERIALS ON THE APPLICATION AND/OR SITE. THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IN CONNECTION WITH INVOEASE’S SERVICE, CONTENT, MATERIALS AND APPLICATIONS IS BORNE BY YOU. INVOEASE SHALL NOT BE RESPONSIBLE FOR ANY LOSS OF DATA OR OTHER MATERIAL. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
            </li>

            <li>
              <strong>Limitation of Damages.</strong> YOU EXPRESSLY UNDERSTAND AND AGREE THAT INVOEASE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU AND/OR ANY OTHER PERSON FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF INVOEASE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), THAT ARISE OUT OF OR ARE RELATED TO INVOEASE’S SERVICE OR ITS REWARDS, OR TO ANY BREACH OF THIS AGREEMENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT OF FAULT, TORT (INCLUDING NEGLIGENCE) OR STRICT OR PRODUCT LIABILITY.  YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT INVOEASE IS NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD INVOEASE LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING YOUR CUSTOMERS, OTHER USERS OF INVOEASE’S SERVICE, AND OPERATORS OF EXTERNAL SITES/APPLICATIONS AND SERVICES—INCLUDING BUT NOT LIMITED TO THIRD-PARTY PAYMENT PROCESSORS OF YOUR BILLING PAYMENTS TO INVOEASE.  TO THE FULLEST EXTENT ALLOWED BY LAW, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH INVOEASE IS TO STOP USING INVOEASE’S SERVICE, AND TO CANCEL YOUR ACCOUNT. YOU HEREBY RELEASE INVOEASE AND INVOEASE’S AFFILIATES FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
            </li>

            <li>
              <strong>Indemnification.</strong> You hereby agree to indemnify, defend, and hold INVOEASE harmless from and against any and all costs or expenses, including reasonable attorney’s fees, in connection with any claims made or actions brought against INVOEASE that arise from (i) Your negligence, (ii) Your misuse or unauthorized use of INVOEASE’s Service, (iii) INVOEASE’s reasonable reliance on any information from or representation by You, (iv) any act or omission of You, or (v) any breach of this Agreement by You.  The indemnifications granted herein shall survive the expiration or termination of the Agreement.
            </li>

            <li>
              <strong>No Assignment or Subleasing.</strong> You shall not encumber, assign, or otherwise transfer this Agreement, any right or interest in or under this Agreement, or any right or interest in Your Subscription without the prior express written consent of INVOEASE.  You shall not sublease Your Subscription, or any part thereof, or allow any other persons, other than Your agents and employees, to use Your Subscription or any part thereof without the prior written consent of INVOEASE.  Any encumbrance, assignment, transfer, or subletting without the prior written consent of INVOEASE, whether it be voluntarily or involuntary, by operation of law, or otherwise, is void and shall, at the option of INVOEASE, terminate this Agreement.
            </li>

            <li>
              <strong>Authority.</strong> By signing this Agreement, the person signing on behalf of any entity or organization that is not a real person (i.e., an individual) represents that he or she is duly authorized by the entity on behalf of which he or she is signing this Agreement to bind such entity and its successors and assigns to the responsibilities, duties, and obligations set forth herein.
            </li>

            <li>
              <p><strong>Miscellaneous</strong></p>
              <ul>
                <li><strong>Modifications.</strong> INVOEASE reserves the right, at any time, to change any provision of this Agreement, without advanced notice to you. If any such change is unacceptable to you, your sole remedy is to terminate Your Subscription. Your continued use of INVOEASE's Service following the effective date of a change to the terms and conditions of this Agreement shall constitute your acceptance of such change.</li>

                <li><strong>Relationship of the Parties.</strong> INVOEASE’s relationship with You is that of a service provider, and nothing in this Agreement is intended to, or should be construed to, create a partnership, agency, joint venture or employment relationship by or between the parties.</li>

                <li><strong>Relationship between this Agreement and others between You and INVOEASE.</strong> In the event of an express conflict between the terms of this Agreement and the Terms and Conditions of INVOEASE’s Service or any other agreement between You and INVOEASE, this Agreement shall control.  However, this provision in no way limits the applicability of any provision of this Agreement or the Terms and Conditions of INVOEASE’s Service or any other agreement between You and INVOEASE, the parties’ rights and obligations under such agreements are in addition to each other.</li>
                
                <li><strong>Governing Law, Venue, Attorney’s Fees.</strong> This Agreement shall be subject to and governed by the laws of the State of Illinois without regard to its conflict of law principles.  The Parties agree and consent that any and all claims, causes of action or disputes (regardless of theory) arising out of or relating to this Agreement, or the relationship between you and INVOEASE, shall be brought exclusively in the courts of the State of Illinois located in Chicago, Illinois.  If INVOEASE has to bring any proceeding or cause of action (whether as a claim, a counterclaim, or otherwise) relating to your violation of the terms of this Agreement, or if INVOEASE successfully defends a proceeding or cause of action brought by you based in whole or in part on your violation of the terms of this Agreement, then you will be responsible for paying the reasonable attorney’s fees and other expenses incurred by INVOEASE in instituting and prosecuting, or defending, such proceeding or cause of action.</li>
                
                <li><strong>Compliance with Laws.</strong> Both parties agree to comply with all applicable federal, state, and local laws, executive orders and regulations issued, where applicable.</li>
                
                <li><strong>Notice.</strong> Any notice given pursuant to this Agreement shall be in writing and shall be given by personal service, e-mail, overnight courier, or by United States certified mail, return receipt requested, postage prepaid to the addresses appearing at the end of this Agreement, or as changed through written notice to the other party.  Notice given by personal service shall be deemed effective on the date it is delivered to the addressee, notice given by email shall be deemed effective on the day following the day it is sent, notice given by overnight courier shall be deemed effective on the day following its placement with the overnight courier, and notice mailed shall be deemed effective on the third day following its placement in the mail addressed to the addressee.  You agree to be served such notices at the contact information You provided when you registered Your account.  
                  <p>All notices to INVOEASE shall be delivered to: INVOEASE LLC, <a href="mailto:info@invoease.com">info@invoease.com.</a></p>
                </li>
                
                <li><strong>No Waiver.</strong> The failure of either party at any time to require performance by the other party of any provision of this Agreement shall in no way affect that party’s right to enforce such provisions, nor shall the waiver by either party of any breach of any provision of this Agreement be taken or held to be a waiver of any further breach of the same provision.</li>
                
                <li><strong>Entire Agreement; Amendments; Severability.</strong> This Agreement constitutes the complete and exclusive statement of the agreement between the parties relating to the subject matter hereof and supersedes all proposals, oral or written, and all other representations, statements, negotiations and undertakings relating to the subject matter.  No waiver, amendment, modification of, or addition to, any provisions of this Agreement shall be binding upon either party unless in writing signed by an authorized representative of such party.  In the event any one or more of the provisions of this Agreement shall be held by a court of competent jurisdiction to be invalid, illegal or unenforceable, the remaining provisions of this Agreement shall remain in effect and this Agreement shall be read as though the offending provision had not been written.</li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
}

export default BillingSubscription;