import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import FaQues, { FaAns } from '.././features/faqContent';

function LoveInvoease(props) {
  return(
    <Container className="py-3" >
      <Row>
        <Col md="4" className="loveInvoease_bg">
        </Col>
        
        <Col md="8">
          <ul className="list-unstyled text-left pl-md-5">
            <li className="mb-4">
              <FaQues key="q1" title="No Delays with the Payment" />
              <FaAns key="a1" title="As a IT Consultant, Getting Paid on Time would be one of the biggest challenge in their industry. You as a Consultant Need to Create Weekly Timesheets and create an Invoice accordingly and Send it to the Client. Your Client can Simply send you the Payment through your Invoice which will be stored in your Logs As Long as you want." />
            </li>

            <li className="mb-4">
              <FaQues key="q2" title="Managing all the Finances in One Place" />
              <FaAns key="a2" title="When it comes to Finances, a lot of Consultants make it over-complicated because they don’t have enough resources available to manage all their Finances in one place. Either they need to Use Multiple Financing Platforms to Generate one output or They Need to Do the Financing manually. Invoease is now Providing EVERYTHING an IT Consultant Need in terms of Finances." />
            </li>

            <li className="mb-4">
              <FaQues key="q3" title="Easy to Manage Timesheets and Clients" />
              <FaAns key="a3" title="As a Consultant Grow, it becomes very difficult for them to Manage all the Payment and The Corresponding Client Details Manually. Invoease allows you to Add the Client Details ONCE and use it for Lifetime. During the Payment Time, it becomes very easy for them to Track Their Timesheets and Deliver it to the Client." />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
export default LoveInvoease;