import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import ScrollAnimation from 'react-animate-on-scroll';

import SectionTitle from '.././common/sectiontitle';

import FeaturesTitle, {FeaturesText} from '../home/featuresContent';

import { LandingFeatures01, LandingFeatures02, LandingFeatures03, LandingFeatures04, LandingFeatures05, LandingFeatures06  } from '../common/assets';

import {Feature01, Feature02, Feature03} from '../common/assets';

function LandingFeatures(){
  return(
    <Container className="py-5 text-center feature landing_features">
      <SectionTitle title={['Core Features of', <br key={1}/>, 'Invoease']} color="title-dark" />

        <ScrollAnimation animateIn='fadeIn' animateOnce={ true }>
          <Row className="text-md-left text-center">
            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures01} alt="Easy Invoicing" className="features-icon" />
              <FeaturesTitle title="Easy Invoicing" className="title-left"/>
              <FeaturesText title="Create professional invoices in any currency in less than 60 seconds, and easily send them to your clients." />
            </Col>
          
            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures02} alt="Accept Payments Online" className="features-icon" />
              <FeaturesTitle title="Accept Payments Online" className="title-left"/>
              <FeaturesText title="Receive Payments Instantly from ANYWHERE. Your Clients can simply send you the payment through your Invoice" />
            </Col>
            
            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures03} alt="Create Taxes and Finance Report" className="features-icon" />
              <FeaturesTitle title="Create Taxes and Finance Report" className="title-left"/>
              <FeaturesText title="Easily Manage your Income and Expenses, monthly/yearly statements and Accounts Receivable." />
            </Col>
          </Row>
        </ScrollAnimation>

        <ScrollAnimation animateIn='fadeIn' animateOnce={ true }>
          <Row className="text-md-left text-center">
            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures04} alt="Manage Multiple Clients" className="features-icon" />
              <FeaturesTitle title="Manage Multiple Clients" className="title-left"/>
              <FeaturesText title="One Client or More Client. Managing Clients Details should be your Regular Habit. Now you can Easily Manage your Clients through Invoease as you Grow." />
            </Col>

            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures05} alt="Payments Tracking System" className="features-icon" />
              <FeaturesTitle title="Payments Tracking System" className="title-left"/>
              <FeaturesText title="Track all your Client’s move Easily. All your Client’s Current Status regarding your invoice will be reflected on your Dashboard with Easy Tracking System" />
            </Col>

            <Col lg="4" md="6" className="mb-4">
              <img src={LandingFeatures06} alt="Import and Export Data" className="features-icon" />
              <FeaturesTitle title="Import and Export Data" className="title-left"/>
              <FeaturesText title="Whether you want to import your existing Data or Export Current Data, Invoease Provides you all the Features in One Click." />
            </Col>
          </Row>
        </ScrollAnimation>

    </Container>
  );
}

export default LandingFeatures;