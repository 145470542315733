import React from 'react';

import ReactDOM from "react-dom";

import {
  Container,
  Row,
  Col,
  UncontrolledCollapse,
  Button,
} from 'reactstrap';

import { Accordion } from './Accordion';

import PageTitle, { PageSubTitle } from '../common/pageTitle';

import FeaturesTitle, {TopicTitle, TopicText} from '.././home/featuresContent';

import StartupBusiness from '../home/startupBusiness';

import {FeatureBg01, FeatureBg02, FeatureBg03} from '../common/assets';

function FeaturesPage(){
  return(
    <div>
      <Container fluid className="features d-flex justify-content-center align-items-center blue-bg py-5" >
        <div className="text-center my-5 py-4">

          <PageTitle title="Features"/>

          <PageSubTitle title="All the features you don’t know you were missing" />

        </div>
      </Container>

      <Container>
        
      </Container>

      <Container fluid className="py-5">
        {/* Features Topic 1 */}
        <Row className="text-md-left text-center d-flex justify-content-center align-items-center">
          <Col lg="7" md="6" className="px-0 mb-3 mb-md-0">
            <img src={FeatureBg01} alt="Feature-Bg-01"  className="img-responsive w-100" />
          </Col>

          <Col lg="5" md="6" className="pl-md-5">
            <FeaturesTitle title="Easy Invoicing" className="title-left no-border black"/>

            <Accordion open={0}>
              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Invoice Management & Tracking" />
                </Accordion.Header>
                
                <Accordion.Body>
                  <TopicText title="Manage all your Client’s Invoices and Payment Status in one place! Create and Manage Invoices and track it’s Current Status Easily"/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Printed & Electronic Invoicing" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Send/Receive Invoices in Electronic Form and Store it on your Account as long as you want. Print it and keep a record in printed form whenever required."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Invoice Complete Log History" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="All your Sent/Received Invoices will be stored in the Log History and are Highly Secured. Access the Log History Anytime, Anywhere!"/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Automatic Recurring Invoices" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Setup Automatic Recurring Invoices according to your Business Needs. The Invoices will sent to your Clients at a regular interval automatically so that you can Focus on other Important work."/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        {/* Features Topic 2 */}
        <Row className="text-md-right text-center d-flex justify-content-center align-items-center py-5 my-5 flex-md-row-reverse">
          <Col lg="7" md="6" className="px-0 mb-3 mb-md-0">
            <img src={FeatureBg02} alt="Feature-Bg-02"  className="img-responsive w-100" />
          </Col>

          <Col lg="5" md="6" className="pr-md-5">
            <FeaturesTitle title="Quick Payments" className="title-right no-border black"/>
            
            <Accordion open={0}>
              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Different Payment Methods" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Invoease Provides Multiple Payments methods to help you receive payments through Different Payment options. Integrate the Required Payment Options with your Invoices according to your Business Needs."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Deposit & Partial Payments" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Deposit Payments allows you to receive a part of the Final Payment as an advance to secure the sale. While Partial Payments allows you to receive payments partially of the total amount and will be flaged to Paid status after the Full Amount is being done."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Supplier and Purchase orders" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Get Acknowledged from your Suppliers for the Product/Services you’ve ordered by issuing a Supplier and Purchase Order using Invoease."/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        {/* Features Topic 3 */}
        <Row className="text-md-left text-center d-flex justify-content-center align-items-center">
          <Col lg="7" md="6" className="px-0 mb-3 mb-md-0">
            <img src={FeatureBg02} alt="Feature-Bg-03"  className="img-responsive w-100" />
          </Col>

          <Col lg="5" md="6" className="pl-md-5">
            <FeaturesTitle title="Automations" className="title-left no-border black"/>

            <Accordion open={0}>
              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Automatic Recurring Invoices" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Setup Automatic Recurring Invoices according to your Business Needs. The Invoices will sent to your Clients at a regular interval automatically so that you can Focus on other Important work"/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Converts Quotes to Invoices" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Creating Quotes and Invoices Both can be very time consuming. Convert quotes to invoices in less than 2 Seconds using Invoease."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                 <TopicTitle title="Payments Tracking" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Invoease helps you to Track Each and every Payment Status of your Payments issued by the Client in order to keep you updated."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Taxes and Finance Reports" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Get all your Taxes and Financing Report in one place! Easily Calculate what are the total taxes of your Business"/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </Col>
        </Row>

        {/* Features Topic 4 */}
        <Row className="text-md-right text-center d-flex justify-content-center align-items-center py-5 my-5 flex-md-row-reverse">
          <Col lg="7" md="6" className="px-0 mb-3 mb-md-0">
            <img src={FeatureBg03} alt="Feature-Bg-02"  className="img-responsive w-100" />
          </Col>

          <Col lg="5" md="6" className="pr-md-5">
            <FeaturesTitle title="Other Features" className="title-right no-border black"/>
            
            <Accordion open={0}>
              <Accordion.Item>
                <Accordion.Header>
                <TopicTitle title="Data Backup, Export and Import" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Data Stored in your System is risky as you might lose everything if your System Crashes. Invoease allows you to take a backup of the Financing Reporting of your Business which will be accessible to your FOREVER. Easily Import and Export Data whenever required."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Customisable Invoice Template" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Invoease has over 50+ Professional Invoice Templates to help you create it as Fast as possible to save your time."/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Time Tracking Tools and Reports" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Invoease has over 10+ tools to Help you Track your Finances. Analyze all your Financing Reports in one place!"/>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Header>
                  <TopicTitle title="Timesheets" />
                </Accordion.Header>

                <Accordion.Body>
                  <TopicText title="Make your Employees more Interested towards creating Timesheets using Invoease. Your Employees can create Timesheets in a matter of 2 minutes and you can verify and approve(or reject) it."/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      {/* <Testimonials /> */}

      <StartupBusiness />

    </div>
  );
}

export default FeaturesPage;

