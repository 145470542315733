import React from 'react'
import{
  Container,
  Row,
  Col,
} from 'reactstrap';

import {BusinessStats1, BusinessStats2, BusinessStats3} from './assets'

import FeaturesTitle, {FeaturesText} from '../home/featuresContent';

function BusinessStats() {
  return(
    <Container className="text-center">
      <Row>
        <Col md="4">
          <img src={BusinessStats1} alt="Countries" className="features-icon mb-3" />
          <FeaturesTitle title="36" className="no-border"/>
          <FeaturesText title="Countries"/>
        </Col>

        <Col md="4">
          <img src={BusinessStats2} alt="Registered Business" className="features-icon mb-3" />
          <FeaturesTitle title="10,000+" className="no-border"/>
          <FeaturesText title="Registered business"/>
        </Col>

        <Col md="4">
          <img src={BusinessStats3} alt="Successfully sent invoices" className="features-icon mb-3" />
          <FeaturesTitle title="45,000+" className="no-border"/>
          <FeaturesText title="Invoices Sent"/>
        </Col>
      </Row>
    </Container>
  );
}

export default BusinessStats;