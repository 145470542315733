import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Button
} from "reactstrap";

import PropTypes from "prop-types";

export class Accordion extends React.Component {
  state = {
    open: this.props.open
  };

  toggleSection = index => () => {
    this.setState(({ open }) => ({
      open: index === open ? undefined : index
    }));
  };

  render() {
    return (
      <div className="accordion">
        {React.Children.map(this.props.children, (child, index) => {
          if (child.type !== AccordionItem) return null;
          return React.cloneElement(child, {
            isOpen: child.props.open || this.state.open === index,
            onClick: this.toggleSection(index)
          });
        })}
      </div>
    );
  }
}

Accordion.propTypes = {
  open: PropTypes.number
};

const AccordionItem = ({ children, isOpen, onClick }) => (
  <div>
    {React.Children.map(children, child => {
      if (child.type === AccordionHeader) {
        return React.cloneElement(child, { onClick });
      }

      if (child.type === AccordionBody) {
        return React.cloneElement(child, { isOpen });
      }

      return null;
    })}
  </div>
);

const AccordionHeader = ({ children, onClick }) => (
  <div>
    <h5 className="m-0">
      <Button color="link" onClick={onClick} className="p-0 text-capitalize mt-2 mb-0">
        {children}
      </Button>
    </h5>
  </div>
);

const AccordionBody = ({ children, isOpen }) => (
  <Collapse isOpen={isOpen} className="mt-2">
    {children}
  </Collapse>
);

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
