import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import SectionTitle from '.././common/sectiontitle';

import {FeaturesText} from './featuresContent';

import {WhyInvoeaseBg} from '../common/assets';

export default class WhyInvoease extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return(
      <Container fluid className="light-bg py-5 why-invoease">
        <Container>
          <Row>
            <Col md="12" className="text-center">
              <SectionTitle title="Why Technology Consulting firms love Invoease?" />
            </Col>
            <Col md="6" className="text-left">
              <FeaturesText title="Invoease has been working with many IT Consulting firms over decade. Since we have many years of track record in helping IT Consulting companies sending and collecting payments from their clients. During this process, we understood how important an employee’s/Consultant’s approved timesheets plays big role on collecting the payments on time from their vendors." />
  
              <FeaturesText title="Most of the time IT Consulting companies employ their HR Staff offshore. HR Staff engages with consultants and follows up weekly to receive the vendor and/or end client’s approved timesheets. It can be very tedious process. Many companies had to go back and forth with their employees and clients in order to send the right amount for the invoices. Sometimes the hours entered were not matching with end client’s timesheets or their employees were too slow sending their approved hours. We have many IT Consulting companies requested to solve this puzzle by automating this process. We can proudly say that we have put together great automated timesheets submitting, tracking and converting those timesheets into invoices in matter of seconds with high level of accuracy." />
            </Col>
  
            <Col md="6">
              <FeaturesText title="If you are IT consulting company struggling with your ongoing invoice and timesheets problems then watch the video below:" />
              
              <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
                <ModalBody>
                  <div className="video-container">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9Zu9KqyLZsA?rel=0&amp;showinfo=0&autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                  </div>
                </ModalBody>
              </Modal>

              <div className="video" onClick={this.toggle}>
                <img src={WhyInvoeaseBg} width="100%" />
              </div>
              
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

