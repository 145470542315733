import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import ScrollAnimation from 'react-animate-on-scroll';

import SectionTitle from '.././common/sectiontitle';
import {Invoice01, Invoice02, Invoice03, Invoice04, Invoice05} from '../common/assets';

function Invoices(){
  return(
    <Container fluid className="dark-bg invoces text-center py-5">
      
      <SectionTitle color="title-light" title={['Invoice that Suits', <br key={1}/>, ' Your Brand']}/>
      
      <Row>
        <Col sm="12" className="d-flex justify-content-center">
          <ScrollAnimation animateIn='fadeIn' animateOnce={ true } className="align-self-end img-small img-left-1 pb-3" delay={250}>
            <img src={Invoice01} alt="Invoice-01"  className="img-responsive" />
          </ScrollAnimation>
          
          <ScrollAnimation animateIn='fadeIn' animateOnce={ true } className="align-self-end img-medium img-left-2 pb-2" delay={150}>
            <img src={Invoice02} alt="Invoice-02" className="img-responsive" />
          </ScrollAnimation>
          
          <ScrollAnimation animateIn='fadeIn' animateOnce={ true } className="align-self-end img-large img-center">
            <img src={Invoice03} alt="Invoice-03" className="img-responsive"/>
          </ScrollAnimation>
          
          <ScrollAnimation animateIn='fadeIn' animateOnce={ true } className="align-self-end img-medium img-right-2 pb-2" delay={150}>
            <img src={Invoice04} alt="Invoice-04" className="img-responsive" />
          </ScrollAnimation>
          
          <ScrollAnimation animateIn='fadeIn' animateOnce={ true } className="align-self-end img-small img-right-1 pb-3" delay={250}>
            <img src={Invoice05} alt="Invoice-05" className="img-responsive" />
          </ScrollAnimation>
        </Col>
      </Row>
      
    </Container>
  );
}

export default Invoices;