import React from 'react';

import { 
  Container,
  Row,
  Col,
} from 'reactstrap';

import { Link } from 'react-router-dom';

function Terms() {
  return (
    <Container className="py-5">
      <Row>
        <Col className="text-left terms mt-3">
          <p>This document (“the Agreement”) sets forth a legally binding agreement between you and INVOEASE LLC (“INVOEASE”).  By accessing or using in any manner, any application, software, or website made available by INVOEASE (together, “INVOEASE’s Service”), you agree to be bound by the terms of use set forth in the Agreement.</p>

          <p className="text-uppercase"><strong>THIS AGREEMENT affectS your legal rights and obligations with respect to INVOEASE. If you do not agree to be bound by all of the Terms of Use SET FORTH IN THIS AGREEMENT, do not access or use ANY PORTION OF INVOEASE’s Service.</strong></p>

          <ol>
            <li>
              <strong>INVOEASE LICENSE.</strong>
              <p>INVOEASE hereby grants you a non-transferable, non-exclusive, terminable license to view and use INVOEASE’s Service for your own personal use.  The license granted to you is conditioned upon your proper conduct and compliance with this Agreement, as determined by INVOEASE in its sole discretion.</p>
            </li>

            <li>
              <strong>OVERVIEW OF INVOEASE’S SERVICE.</strong>
              <p>INVOEASE’s Service offers you a cost-effective subscription platform for sending Invoicing and estimates to your clients, also timesheet application for your employees. INVOEASE’s Service enables you to send invoices to your clients get their approval on estimates and running your employee timesheets online through INVOEASE platform. <strong>INVOEASE CANNOT AND DOES NOT GUARANTEE ANY INVOICE WILL CLEAR.  INVOEASE WILL NOT BE HELD ACCOUNTABLE AND DOES NOT ASSUME ANY LIABILITY FOR ANY BANK FEES OR ANY TYPE OF FEES YOU MAY INCUR AS A RESULT OF A PAYMENT FROM CLIENTS THAT DOES NOT CLEAR.</strong></p>
            </li>

            <li>
              <strong>YOUR ACCOUNT.</strong>
              <p>In order to use INVOEASE’s Service, you are required to request access, and then create and register for an account (an “Account”).  When requesting access and registering for, or updating an Account, you provide us with certain personal information, including your name, mailing address, your credit card or other payment information (“Your Payment Information”), e-mail address and a password.  This information will be maintained and used in accordance with our <Link to="/privacy">Privacy Policy</Link>. If you provide an email account or payment information that is inactive, non-existent, untrue, inaccurate, or not current, or INVOEASE has reasonable grounds to suspect that such information is or becomes untrue, inaccurate, or not current, INVOEASE has the right to deny you access to INVOEASE’s Service, or suspend or terminate your Account and refuse any and all current or future use of the INVOEASE Service (or any portion thereof).  You are responsible for maintaining the confidentiality of your password and other information you use to login to your Account, and you are fully responsible for all activities that occur under your Account.  You agree to immediately notify INVOEASE of any unauthorized use of your Account or any other breach of security.</p>
            </li>

            <li>
              <strong>YOUR USE OF INVOEASE’S SERVICE.</strong>
              <p>When you use INVOEASE’s Service, you represent and agree to the following:</p>

              <ul>
                <li>You are at least thirteen (13) years old;</li>

                <li>You have the authority and capacity to enter into and be bound by this Agreement;</li>

                <li>Prior to using INVOEASE’s Service for any transaction to verify account information or generate invoice for payment, you will obtain all necessary authorization from your customer and/or the owner of the subject account to utilize the relevant financial institution information, account numbers and routing numbers, and/or to card details for the payment amount entered into INVOEASE’s Service;</li>

                <li>You agree to keep current your payment information for subscription billing or otherwise timely pay the fee due for your subscription to INVOEASE’s Service;</li>

                <li>You will not use INVOEASE’s Service to communicate expressions of slander, libel, hatred, bigotry, racism, obscenity, pornography, vulgarity, or other objectionable speech, and you agree that INVOEASE has the right—but not the obligation—to filter, take down or remove anything you submit to INVOEASE’s Service (such as reviews or inquiries) which contains materials that INVOEASE, in its sole discretion, determines to be expressions of slander, libel, hatred, bigotry, racism, obscenity, pornography, vulgarity, or other objectionable speech;</li>

                <li>You will use INVOEASE’s Service with only an honest and good faith intent;</li>

                <li>You will not upload or use INVOEASE’s Service to share viruses, spyware, or any other computer code, files or programs designed to interrupt, destroy, affect, hinder or limit the functionality of INVOEASE’s Service, any other operations of INVOEASE, or the resources of other users of INVOEASE’s Service;</li>

                <li>You will not use INVOEASE’s Service in a way that violates any state, federal, or international laws or regulations, including but not limited to Electronic Funds Transfer Act, Regulation E, and any laws and regulations relating to unauthorized disclosures of third-party financial account information;</li>

                <li>You agree to comply with all applicable rules at the time You make an entry of Your customer’s payment information, with respect to NACHA’s most recent Guidelines, and that each entry shall in no way breach any applicable provision of the Operating Rules of NACHA;</li>

                <li>You will not communicate with INVOEASE employees or customer service agents in a manner which is abusive, obscene, untrue, or misleading;</li>

                <li>You will not interfere with, disrupt or circumvent any security feature of INVOEASE’s Service or any feature that restricts or implements limitations on the use of or access to INVOEASE’s Service;</li>

                <li>You will not attempt to get password, account information or other private or personal information from another user or from INVOEASE;</li>

                <li>You will not reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code or object code for any underlying software or other intellectual property used in INVOEASE’s Service, or to obtain any information from INVOEASE’s Service using any method not expressly permitted by INVOEASE; and</li>

                <li>You will not upload content that you do not have rights to copy or publish or that infringe the copyrights, trademarks or other intellectual property rights of INVOEASE or any third party.</li>
                </ul>
            </li>

            <li>
              <strong>TERMINATION.</strong>
              <p>This Agreement, and any amendments that may be made to it from time to time, will remain in effect until you cancel your subscription to INVOEASE’s Service, with the exception of sections 7-11, which shall survive the termination of this Agreement.  You may terminate this Agreement and your Account by contacting INVOEASE at least seven (7) days prior to the end of your subscription month, in which case Your Account will remain active until and be terminated at the end of your subscription month.  If you terminate less than seven (7) days prior to the end of your subscription month, Your Account will be billed for the next subscription month and then remain active until and be terminated at the end of your next subscription month.  You will not receive a refund for any time remaining in your subscription term, regardless of how much time is left on your term or when your subscription renewed.</p>

              <p>You agree that INVOEASE may, without prior notice, immediately terminate, cancel, or suspend your Account for cause.  Cause for such termination, cancelation, or suspension shall include, but not be limited to, (i) breach or violations of this Agreement or other incorporated agreements, (ii) non-payment of your subscription fee, (iii) requests by law enforcement or other government agencies, (iv) discontinuance or material modification to INVOEASE’s Service (or any part thereof), (v) unexpected technical or security issues or problems, and/or (vi) engagement by you in fraudulent or illegal activities. Further, you agree that all terminations, cancelations, and suspensions for cause shall be made in INVOEASE’s sole discretion and that INVOEASE shall not be liable to you or any third party for any termination of your account or access to INVOEASE’s Service.</p>
            </li>

            <li>
              <strong>MODIFICATIONS TO SERVICE.</strong>

              <p>INVOEASE reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, INVOEASE’s Service (or any part thereof) with or without notice. INVOEASE makes no representations with respect to the availability of INVOEASE’s Service at any particular time. You agree that INVOEASE shall not be liable to you or to any third party for any modification, suspension, unavailability, or discontinuance of INVOEASE’s Service (or any part thereof).</p>
            </li>

            <li>
              <strong>INDEMNITY.</strong>

              <p>You agree to indemnify and hold INVOEASE and its subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless from all costs, liabilities, and damages INVOEASE incurs, including without limitation its reasonable attorneys’ fees, with respect to all claims, demands, proceedings, and actions of any kind whatsoever, made or brought by any third party due to or arising out of your use or misuse of INVOEASE’s Service, your connection to INVOEASE’s Service, your violation of any term of this Agreement, or your violation of any rights of another.</p>
            </li>

            <li>
              <strong>DISCLAIMER OF WARRANTIES.</strong>
              <p>INVOEASE’S SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND. INVOEASE HEREBY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT; AVAILABILITY OF INVOEASE’S SERVICE; LACK OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER CODE THAT MANIFESTS CONTAMINATING OR DESTRUCTIVE PROPERTIES; ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, CURRENCY, OR USEFULNESS OF ANY INFORMATION THROUGH INVOEASE’S SERVICE—INCLUDING BUT NOT LIMITED TO ANY INFORMATION THAT IS SUBMITTED BY ANY CUSTOMER OF YOURS AND ANY TYPOGRAPHICAL ERRORS BY INVOEASE OR ANY OTHER USER; AND ANY DUTIES OF REASONABLE CARE, WORKMANLIKE EFFORT OR LACK OF NEGLIGENCE IN CONNECTION WITH INVOEASE’S SERVICE, CONTENT AND MATERIALS ON THE APPLICATION AND/OR SITE. THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IN CONNECTION WITH INVOEASE’S SERVICE, CONTENT, MATERIALS AND APPLICATIONS IS BORNE BY YOU. INVOEASE SHALL NOT BE RESPONSIBLE FOR ANY LOSS OF DATA OR OTHER MATERIAL. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>

              <p><strong>YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL NSF FEES OR ANY OTHER ADMINISTRATIVE OR PUNITIVE FEES THAT YOU INCUR WHEN A ACH PAYMENT YOU HAVE OBTAINED THROUGH INVOEASE’S SERVICE IS RETURNED DUE TO INSUFFICIENT FUNDS, REGARDLESS OF ANY INFORMATION INVOEASE PROVIDED TO YOU IN RESPONSE TO YOUR REQUEST FOR VERIFICATION OF THE ACH SENDER’S ACCOUNT.</strong></p>
            </li>

            <li>
              <strong>LIMITATION OF LIABILITY.</strong>

              <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT INVOEASE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU AND/OR ANY OTHER PERSON FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF INVOEASE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), THAT ARISE OUT OF OR ARE RELATED TO INVOEASE’S SERVICE OR ITS CONTENTS, OR TO ANY BREACH OF THIS AGREEMENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT OF FAULT, TORT (INCLUDING NEGLIGENCE) OR STRICT OR PRODUCT LIABILITY.  YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT INVOEASE IS NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD INVOEASE LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING YOUR CUSTOMERS, OTHER USERS OF INVOEASE’S SERVICE, AND OPERATORS OF EXTERNAL SITES/APPLICATIONS AND SERVICES—INCLUDING BUT NOT LIMITED TO THIRD-PARTY PAYMENT PROCESSORS OF YOUR BILLING PAYMENTS TO INVOEASE.  TO THE FULLEST EXTENT ALLOWED BY LAW, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH INVOEASE IS TO STOP USING INVOEASE’S SERVICE, AND TO CANCEL YOUR ACCOUNT. YOU HEREBY RELEASE INVOEASE AND INVOEASE’S AFFILIATES FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.</p>
            </li>

            <li>
              <strong>INTELLECTUAL PROPERTY.</strong>
              <p>You agree that all of INVOEASE’s patents, copyrights, trademarks, trade names, service marks and other INVOEASE logos and brand features, and product and service names are intellectual property that is and shall remain solely owned by INVOEASE (the “INVOEASE IP”). You agree not to display or use in any manner the INVOEASE IP, without INVOEASE’s prior permission.</p>
            </li>

            <li>
              <strong>MISCELLANEOUS.</strong>

              <ul>
                <li><strong>Modifications. </strong> INVOEASE reserves the right, at any time, to change the terms and conditions of this Agreement, without advanced notice to you. If any such change is unacceptable to you, your sole remedy is to terminate Your Account. Your continued use of INVOEASE’s Service following the effective date of a change to the terms and conditions of this Agreement shall constitute your acceptance of such change.</li>

                <li><strong>Relationship of the Parties. </strong> INVOEASE’s relationship with You is that of a service provider, and nothing in this Agreement is intended to, or should be construed to, create a partnership, agency, joint venture or employment relationship by or between the parties.</li>

                <li><strong>Ownership of your comments or suggestions to INVOEASE. </strong> If you provide any suggestions, input or other feedback relating to INVOEASE’s Service, INVOEASE shall have the right to freely and fully exercise and exploit any such suggestions, input or other feedback in connection with its business, without notice to, approval by, attribution to or compensation to you.</li>

                <li><strong>Entire Agreement. </strong> This Agreement constitutes the entire agreement between you and INVOEASE and governs your use of INVOEASE’s Service.  This Agreement supersedes and replaces any prior version of this Agreement between you and INVOEASE with respect to INVOEASE’s Service. You also may be subject to additional terms and conditions that may apply when you use INVOEASE’s Service, affiliate services, third-party content or third-party software.</li>

                <li><strong>Choice of Law and Forum. </strong> You and INVOEASE each agree that this Agreement and the relationship between the parties shall be governed by the laws of the State of Illinois without regard to conflict of law provisions and that any and all claims, causes of action or disputes (regardless of theory) arising out of or relating to this Agreement, or the relationship between you and INVOEASE, shall be brought exclusively in the courts of the State of Illinois located in Chicago, Illinois. You and INVOEASE agree to submit to the personal jurisdiction of the courts located within Chicago, Illinois, and agree to waive any and all objections to the exercise of jurisdiction over the parties by such courts and to venue in such courts.</li>

                <li><strong>Remedies and Attorney’s Fees. </strong> INVOEASE reserves the right to seek all remedies available at law and in equity for violations of the Agreement.  You agree that if INVOEASE, in its sole discretion, determines you have violated any term or condition of this Agreement and INVOEASE, in its sole discretion, determines that the violation is severe enough, INVOEASE—without advance notice to you—can block access to INVOEASE’s Service from your account and any device and IP address from which you have accessed or attempted to access INVOEASE’s Service.  You agree that the consequences of commercial use or re-publication of content or materials from INVOEASE’s Service or other violations of the terms of this Agreement may be so serious and incalculable that monetary compensation may not be a sufficient or appropriate remedy and that INVOEASE will be entitled to temporary and permanent injunctive relief to prohibit such use or activity, without the need to prove damages or post bond.  You also agree that if INVOEASE has to bring any proceeding or cause of action (whether as a claim, a counterclaim, or otherwise) relating to your violation of the terms of this Agreement, or if INVOEASE successfully defends a proceeding or cause of action brought by you based in whole or in part on your violation of the terms of this Agreement, then you will be responsible for paying the reasonable attorney’s fees and other expenses incurred by INVOEASE in instituting and prosecuting, or defending, such proceeding or cause of action.</li>

                <li><strong>Waiver and Severability. </strong> The failure of INVOEASE to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</li>

                <li><strong>Section Titles. </strong> The section titles in the Agreement are for convenience only and have no legal or contractual effect.</li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
}

export default Terms;