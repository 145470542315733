import React from 'react';

import {
  Container
} from 'reactstrap';

import BillingSubscription from './billingSubscriptionSection';

import PageTitle from '../common/pageTitle';

function Home() {
  return (     
    <div>
      <Container fluid className="d-flex justify-content-center align-items-center blue-bg py-5" >
        <div className="text-center my-5 py-5 small-title">
          <PageTitle title="Billing and Subscription Agreement"/>
        </div>
      </Container>

      <BillingSubscription />
    </div>        
  );
}

export default Home;