import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import FaQues, { FaAns } from '.././features/faqContent';

function LoveInvoeaseCPA(props) {
  return(
    <Container className="py-3" >
      <Row>
        <Col md="4" className="loveInvoease_cpa_bg">
        </Col>
        
        <Col md="8">
          <ul className="list-unstyled text-left pl-md-5">
            <li className="mb-4">
              <FaQues key="q1" title="Managing all your Finances in One Place" />
              <FaAns key="a1" title="When it comes to Finances, a lot of CPA make it over-complicated because they don’t have enough resources available to manage all their Finances in one place. Either they need to Use Multiple Financing Platforms to Generate one output or They Need to Do the Financing manually. Invoease is now Providing EVERYTHING an CPA Need in terms of Finances." />
            </li>

            <li className="mb-4">
              <FaQues key="q2" title="No Delays with the Payment" />
              <FaAns key="a2" title="As a CPA Grows, Getting Paid on Time would be one of the biggest challenge in their industry. You as a CPA Can now create Weekly Timesheets easily and create an Invoice accordingly and Send it to the Client. Your Client can then send you the Payment through your Invoice Directly which will be stored in your Logs As Long as you want." />
            </li>

            <li className="mb-4">
              <FaQues key="q3" title="Easy to Manage Timesheets and Clients Information" />
              <FaAns key="a3" title="As a CPA Grow, it becomes very difficult for them to Manage all the Payment and The Corresponding Client Details Manually. Invoease allows you to Add the Client Details ONCE and use it for Lifetime. During the Payment Time, it becomes very easy for them to Track Their Timesheets and Deliver it to the Client." />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
export default LoveInvoeaseCPA;