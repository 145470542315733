import React from 'react';
import { 
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AccountForm() {
  return (
    <Form>
      <FormGroup>
        <Input type="email" name="email" id="email" placeholder="Email" />
      </FormGroup>
      
      <Button color="blue" size="lg" className="py-3 mb-3 w-100">Send <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button>
    </Form>
  );
}

export default AccountForm;