import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import { Category, FeatureLink } from './featuresContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

function AllFeatures(){
  return(
    <Container fluid className="py-5 blue-bg">
      <Container>
        <Row>
          <Col md="6" lg="3">
            <Category title="Easy Invoicing" />
            <ul className="list-unstyled">
              <li><FeatureLink title="Invoice Management & Tracking"/></li>
              <li><FeatureLink title="Printed & Electronic Invoicing"/></li>
              <li><FeatureLink title="Invoice Complete Log History"/></li>
              <li><FeatureLink title="Automatic Recurring Invoices"/></li>
            </ul>
          </Col>

          <Col md="6" lg="3">
            <Category title="Quick Payments" />
            <ul className="list-unstyled">
              <li><FeatureLink title="Different Payment Methods"/></li>
              <li><FeatureLink title="Deposit & Partial Payment"/></li>
              <li><FeatureLink title="Suppliers & Purchase Orders"/></li>
            </ul>
          </Col>

          <Col md="6" lg="3">
            <Category title="Automations" />
            <ul className="list-unstyled">
              <li><FeatureLink title="Automatic Recurring Invoices"/></li>
              <li><FeatureLink title="Converts Quotes to Invoices"/></li>
              <li><FeatureLink title="Payments Tracking"/></li>
              <li><FeatureLink title="Taxes & Finance Reports"/></li>
            </ul>
          </Col>

          <Col md="6" lg="3">
            <Category title="Other Features" />
            <ul className="list-unstyled">
              <li><FeatureLink title="Data Backup, Export and Import"/></li>
              <li><FeatureLink title="Customisable Email Templates"/></li>
              <li><FeatureLink title="Time Tracking Tools & Reports"/></li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Link to="/features"><Button color="yellow" size="lg">All Features <FontAwesomeIcon icon={['fas', 'arrow-right']} /></Button></Link>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default AllFeatures;